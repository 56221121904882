import React, { Component } from "react";
import { Table, Button, message } from "antd";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Store from "../Store";
import { DELETE_LIST } from "../AdminActions";
import { GET_LISTS, GET_MOST_RECENT_LIST, GET_NUM_LISTS } from "../Actions";
import Loading from "../Components/Loading";

const LIST_LIMIT = 10;

class Lists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      loading: true,

      numLists: 0,
      lists: [],
      pageCache: new Map(),
    };

    this.fetchPage = this.fetchPage.bind(this);
    this.deleteList = this.deleteList.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    this.setState({
      list: await Store.receive(GET_MOST_RECENT_LIST),
      numLists: await Store.receive(GET_NUM_LISTS),
    });
    this.fetchPage(1);
  }

  async fetchPage(page) {
    this.setState({ loading: true });
    if (this.state.pageCache.has(page)) {
      this.setState({
        lists: this.state.pageCache.get(page),
        loading: false,
      });
    } else {
      const offset = (page - 1) * LIST_LIMIT;
      const lists = await Store.receive(GET_LISTS, { offset: offset });
      this.state.pageCache[page] = lists;
      this.setState({
        lists: lists,
        loading: false,
      });
    }
  }

  async deleteList(listId) {
    Store.adminReceive(DELETE_LIST, { listId: listId })
      .then(window.location.reload())
      .then(message.success("Deleted!"))
      .catch(message.error("Could not delete."));
  }

  onChange(params) {
    window.location.reload();
    const response = params.file.response;
    if (params.file.response) {
      const url = response.url;
      this.setState({
        ...this.state,
        list: { ...this.state.list, awsUrl: url },
      });
      // this.state.list.awsURL = url;
      message.success("Successfully added!");
    } else {
      message.error("There was an issue while uploading");
    }
  }

  render() {
    const columns = [
      {
        title: "date",
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "file name",
        dataIndex: "image_file_name",
        key: "fileName",
      },
      {
        title: "delete",
        dataIndex: "id",
        key: "delete",
        render: (id) => (
          <Button onClick={() => this.deleteList(id)}>delete</Button>
        ),
        // render: (id) => <Button onClick={() => this.deleteList(id)}>delete</Button>
      },
    ];

    const paginationConfig = {
      showQuickJumper: true,
      defaultPageSize: LIST_LIMIT,
      showSizeChanger: false,
      defaultCurrent: 1,
      total: this.state.numLists,
      onChange: this.fetchPage,
    };
    if (this.state.loading) {
      return <Loading />;
    }
    return (
      <div id={"list-page"}>
        <h2>the list</h2>
        <div className={"add"}>
          <Button
            className={"admin-button"}
            type={"primary"}
            icon={<UploadOutlined />}
          >
            <Upload
              action={"/api/list/admin/add-list"}
              accept={"image/*"}
              onChange={this.onChange}
            >
              upload new list
            </Upload>
          </Button>
        </div>
        <div id={"recent-list"}>
          <h3>most recent:</h3>
          <img
            src={this.state.list.awsURL}
            alt="most recent list"
            width="30%"
          />
        </div>
        <Table
          columns={columns}
          dataSource={this.state.lists}
          size={"small"}
          pagination={paginationConfig}
        />
      </div>
    );
  }
}

export default Lists;
