import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Store from "../Store";
import {GET_ILLUSTRATOR_ARTICLES, GET_NUM_ILLUSTRATOR_ARTICLES} from "../Actions";
import PaginatedArticleList from "../Components/PaginatedArticleList";

// const articlesToLoad = 20;

class Illustrator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numArticles: 0,
      illustrator: this.props.match.params.illustrator, // grabbing this from the URL
      // pageCache: new Map(),
      // totalDataItems: 0,
      // articles: [],
      // isLoading: true,
    }

    // this.fetchData = this.fetchData.bind(this);
  }

  async componentDidMount() {
    this.setState({
      numArticles: await Store.receive(GET_NUM_ILLUSTRATOR_ARTICLES, {illustrator: this.state.illustrator})
    })
    // this.getTotalDataItems();
    // this.fetchData(1);

  }

  // async getTotalDataItems() {
  //   await fetch("/db/length/section=" + this.state.illustrator)
  //     .then(res => res.json())
  //     .then(res => {
  //       this.setState({
  //         totalDataItems: parseInt(res["count"])
  //       })
  //     });
  // }
  //
  // async fetchData(page) {
  //   let articles;
  //   if (this.state.pageCache.has(page)) {
  //     articles = this.state.pageCache.get(page);
  //   } else {
  //     let offset = (page - 1) * articlesToLoad;
  //     await fetch("/db/illustrator=" + this.state.illustrator + "/" + offset)
  //       .then(res => res.json())
  //       .then(res => {
  //         let newDict = this.state.pageCache;
  //         newDict[page] = res;
  //         this.setState({
  //           pageCache: newDict
  //         });
  //         articles = res;
  //       });
  //   }
  //   this.setState({
  //     articles: articles,
  //     isLoading: false
  //   });
  // }

  getPage(offset) {
    return Store.receive(GET_ILLUSTRATOR_ARTICLES, {illustrator:this.state.illustrator, offset: offset});
  }
  
  render() {
    return (
      <div className={"page-content"}>
        articles illustrated by {this.state.illustrator}
        <PaginatedArticleList numArticles={this.state.numArticles}
                              getPage={(offset) => this.getPage(offset)} />
        {/*<h1 id='section-title'>Articles illustrated by {this.state.illustrator}</h1>*/}
        {/*{*/}
        {/*  this.state.articles.map((article) =>*/}
        {/*    <ArticlePreview key={article.id} {...article}/>*/}
        {/*  )}*/}
        {/*<Pagination className="center" showQuickJumper defaultPageSize={articlesToLoad} showSizeChanger={false} defaultCurrent={1} total={this.state.totalDataItems} onChange={this.fetchData}/>*/}
      </div>
    );
  }
}

export default withRouter(Illustrator);
