import React from 'react';
import {ArticlePreview, FeaturedArticlePreview, FromTheEditors, Loading} from "./components";

export default function Issue(props) {
  const { issue, fte, featuredArticles, regularArticles } = props;
  console.log(props.sectionArticles)
  if (props.loading) {
    return <Loading/>
  }
  return (
    <>
      <div className={"horizontal-scroll-parent"}>
        <div className={"horizontal-scroll-child"}>
          {
            featuredArticles.map((article) =>
              <FeaturedArticlePreview key={article.id} {...article}/>
            )
          }
        </div>
      </div>
      <div>
        <FromTheEditors issue={issue} fte={fte}/>
        <div className={"page-content"}>
          <h1>Current Issue</h1>
          <div id="regular-articles">
            {
              regularArticles.map((article) =>
                <ArticlePreview key={article.id} {...article}/>
              )}
          </div>
        </div>
      </div>
    </>
  );
}