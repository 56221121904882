import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IssueCover } from "../Components/components";
import { Card } from "antd";
import { imageSizes } from "./IssueCover";

class IssuePreviewCard extends Component {
  render() {
    if (this.props.moreIssues) {
      return (
        <Card
          id={"more-issues-card"}
          style={{ width: "100px", height: "150px" }}
          className={"issue-preview-card"}
          hoverable
        >
          <Link to={`/issues`}>
            <h3>more issues</h3>
          </Link>
        </Card>
      );
    } else {
      return (
        <Card className={"issue-preview-card"} hoverable>
          <Link to={`/issue/${this.props.id}`}>
            <IssueCover
              document_number={this.props.document_number}
              size={imageSizes.MEDIUM}
              imageStyle={{ width: "100px", height: "150px" }}
            />
          </Link>
          {/* <div className={"issuu-link"}>
            <Link to={`/issuu/${this.props.id}`}>read as pdf</Link>
          </div> */}
        </Card>
      );
    }
  }
}

export default IssuePreviewCard;
