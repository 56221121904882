import React from 'react';

export const COLLABORATOR_TYPE = {
  "AUTHOR": 0,
  "ILLUSTRATOR": 1
}

function CollaboratorLinks(props) {
  if (props.collaborators) {
    let collaboratorPreLink;
    let collaboratorText;
    switch (props.type) {
      case COLLABORATOR_TYPE.AUTHOR:
        collaboratorPreLink = "/author=";
        collaboratorText = "by";
        break;
      case COLLABORATOR_TYPE.ILLUSTRATOR:
        collaboratorPreLink = "/illustrator=";
        collaboratorText = "Illustration by"
        break;
      default:
    }

    let collaborators = props.collaborators;

    let numCollaborators = collaborators.length;
    let lastIndex = numCollaborators - 1;
    let secondToLastIndex = numCollaborators - 2;

    let collaboratorLinks = [];
    for (let i = 0; i < numCollaborators; i++) {
      const collaborator = collaborators[i];
      const collaboratorLink = <a href={collaboratorPreLink+collaborator} key={i}>{collaborator}</a>;

      collaboratorLinks.push(collaboratorLink)
      if (i === lastIndex) {
        // push nothing
      } else if (i === secondToLastIndex) {
        collaboratorLinks.push(" & ")
      } else if (i < secondToLastIndex) {
        collaboratorLinks.push(", ")
      }
    }
    return (<div className={"collaborators"}>{collaboratorText} {collaboratorLinks}</div>);
  }
  return (<></>);
}

export default CollaboratorLinks;