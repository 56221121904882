module.exports = {
  ADD_ARTICLE: "ADD_ARTICLE",
  UPDATE_ARTICLE: "UPDATE_ARTICLE",
  DELETE_ARTICLE: "DELETE_ARTICLE",
  GET_ARTICLE: "GET_ARTICLE",
  GET_ALL_ARTICLES: "GET_ALL_ARTICLES",
  GET_NUM_ALL_ARTICLES: "GET_NUM_ALL_ARTICLES",
  GET_ARTICLES_FROM_ISSUE: "GET_ARTICLES_FROM_ISSUE",

  ADD_ISSUE: "ADD_ISSUE",
  UPDATE_ISSUE: "UPDATE_ISSUE",
  GET_ISSUE_FROM_ID: "GET_ISSUE_FROM_ID",
  DELETE_ISSUE: "DELETE_ISSUE",
  GET_ALL_ISSUES: "GET_ALL_ISSUES",
  GET_NUM_ALL_ISSUES: "GET_NUM_ALL_ISSUES",
  GET_ISSUES_TO_SELECT: "GET_ISSUES_TO_SELECT",

  GET_EMAILS: "GET_EMAILS",

  // GET_MOST_RECENT_LIST: "GET_MOST_RECENT_LIST",
  // ADD_LIST: "ADD_LIST",
  // UPLOAD_LIST: "UPLOAD_LIST",
  // GET_LISTS: "GET_LISTS",
  DELETE_LIST: "DELETE_LIST",

  GET_ALUMS: "GET_ALUMS",

  GET_CKEDITOR_CONTENTS: "GET_CKEDITOR_CONTENTS",
  ADD_CKEDITOR_FOLDER: "ADD_CKEDITOR_FOLDER",
  UPLOAD_CKEDITOR_IMAGES: "UPLOAD_CKEDITOR_IMAGES",
  DELETE_CKEDITOR_OBJECT: "DELETE_CKEDITOR_OBJECT",

  UPDATE_PAGE: "UPDATE_PAGE",
};
