import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';

// import Actions from '../Actions.js';
import Store from '../Store.js';
import {ADD_ALUM, GET_PAGE} from "../Actions";
import {pages} from "../pages";

const layout = {
  labelCol: { offset: 6, span: 3 },
  wrapperCol: { span: 9 },
};
const centerLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

class Alumni extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      pageId: pages.alumni.id,
      pageBody: "",
      loading: true
    }

    this.addAlum = this.addAlum.bind(this);
  }

  async componentDidMount() {
    const page = await Store.receive(GET_PAGE, { pageId: this.state.pageId })
    this.setState({
      pageBody: page.body,
      loading: false,
    });
  }
  
  async addAlum(values) {
    let alum = {
      name: values.name,
      email: values.email,
      grad_year: values.grad_year,
      staff_position: values.staff_position,
      job_now: values.job_now,
      where_you_live: values.where_you_live
    }
    Store.receive(ADD_ALUM, {alum : alum})
      .catch((err) => {
        console.error(err);
        this.setState({ error: true })
      })
      .finally(() => this.setState({ submitted: true }))
  }

  render() {
    if (this.state.error) {
      return (
        <div id={"alumni-page"} class="page-content">
          Something went wrong, please try again later.
        </div>
      );
    }
    return (
      <div id={"alumni-page"} class="page-content">
        {
          this.state.submitted?
            <h2>Thank you for joining our alumni list! We'll keep in touch :)</h2>
            :
            <>
              <h1>Stay in touch!</h1>
              <h2><i>(Only name and email are required!)</i></h2>
              <div dangerouslySetInnerHTML={{__html: this.state.pageBody}}/>
              <Form
                onFinish={this.addAlum}
                {...layout}>
                <Form.Item label="name" name="name"
                           rules={[{ required: true, message: 'please enter your name!'}]}>
                  <Input placeholder="your name"/>
                </Form.Item>
                <Form.Item label="email" name="email"
                           rules={[{ required: true, message: 'please enter your email!', type: 'email' }]}>
                  <Input placeholder="josiahcarberry@brown.edu"/>
                </Form.Item>
                <Form.Item label="grad year" name="grad_year">
                  <Input placeholder="graduation year"/>
                </Form.Item>
                <Form.Item label="staff position" name="staff_position">
                  <Input placeholder="position on staff"/>
                </Form.Item>
                <Form.Item label="current job" name="job_now">
                  <Input placeholder="where do you work now?"/>
                </Form.Item>
                <Form.Item label="current location" name="where_you_live">
                  <Input placeholder="where do you live now?"/>
                </Form.Item>
                <Form.Item {...centerLayout}>
                  <Button type="primary" htmlType="submit">
                    save info
                  </Button>
                </Form.Item>
              </Form>
            </>
        }
      </div>
    );
  }
}

export default Alumni;
