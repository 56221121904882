import React, { Component } from 'react';
import Store from '../Store';
import {GET_EMAILS} from "../AdminActions";

class Emails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: []
    }
  }

  async componentDidMount() {
    this.setState({
      emails: await Store.adminReceive(GET_EMAILS)
    })
  }

  render() {
    return (
      <div>
        <h1>emails</h1>
        <ul>
          {
            this.state.emails.map((entry) => <li>{entry.email}</li>)
          }
        </ul>
      </div>
    );
  }
}

export default Emails;
