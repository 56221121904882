import React, { Component } from 'react';
import { Collapse,Card } from 'antd';
import { IssueCover } from '../Components/components'
import { Responsive } from "responsive-react";
import {imageSizes} from "./IssueCover";
const { Panel } = Collapse;

class FromTheEditors extends Component {
  render() {
    return (
      <div id="fte">
        <Responsive displayIn={["Mobile", "Tablet"]}>
          <Collapse ghost>
            <Panel header="From the Editors" key="1">
              <FTE fte={this.props.fte} {...this.props}/>
            </Panel>
          </Collapse>
        </Responsive>
        <Responsive displayIn={["Laptop"]}>
          <RecentIssueCover {...this.props}/>
          <FTE {...this.props}/>
        </Responsive>
      </div>
    );
  }
}

function RecentIssueCover(props) {
  return (
    <div id={"issue-cover-parent"}>
        <div id={"img-parent"}>
          <IssueCover document_number={props.issue.document_number}
                      size={imageSizes.ORIGINAL}/>
        </div>
    </div>
  );
}

function FTE(props) {
  return (
    <div id={"fte-article-parent"}>
      <Card id={"fte-article"}>
        <h1>{props.fte.title}</h1>
        <div dangerouslySetInnerHTML={{__html: props.fte.body}}/>
        <h2 id={"by"}><b>{props.fte.by}</b></h2>
      </Card>
    </div>
  );
}

export default FromTheEditors;
