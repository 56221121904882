import React, { Component } from 'react';
import {pages} from "../pages";
import Store from "../Store";
import {GET_PAGE} from "../Actions";

class Apply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageId: pages.apply.id,
      pageBody: "",
      loading: true
    }
  }
  
  async componentDidMount() {
    const page = await Store.receive(GET_PAGE, { pageId: this.state.pageId })
    this.setState({
      pageBody: page.body,
      loading: false,
    });
  }

  render() {
    return (
      <div id={"apply-page"} className={"page-content"}>
        <div dangerouslySetInnerHTML={{__html: this.state.pageBody}}/>
      </div>
    );
  }
}

export default Apply;
