import { Breadcrumb, Button, Input, Modal, Upload, List, Collapse } from "antd";
import React, { Component } from "react";
import CKEditorImagePreview from "./CKEditorImagePreview";
import { FolderAddOutlined, HomeOutlined } from "@ant-design/icons";
import Actions from "../../Actions.js";
import Store from "../../Store.js";
import CKEditorFolderPreview from "./CKEditorFolderPreview";

const EMPTY_CONTENTS = { folders: [], objects: [] };

class CKEditorImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insertImage: props.insertImage,
      visible: false,
      contents: EMPTY_CONTENTS,
      path: "",
      selectedImage: "",
    };

    this.showModal = this.showModal.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.openFolder = this.openFolder.bind(this);
    this.addFolder = this.addFolder.bind(this);
    this.goToFolder = this.goToFolder.bind(this);

    this.getContents = this.getContents.bind(this);
    this.deleteObject = this.deleteObject.bind(this);
    this.selectImage = this.selectImage.bind(this);
  }

  async componentDidMount() {
    await this.getContents(this.state.path);
  }

  async getContents(path) {
    // console.log("Getting contents at " + path + " ... ")
    this.setState({ contents: EMPTY_CONTENTS });
    const contents = await Store.receive(Actions.GET_CKEDITOR_CONTENTS, {
      folder: path,
    });
    this.setState({ contents: contents });
    // console.log(this.state.contents);
  }

  showModal() {
    this.setState({
      visible: true,
      selectedImage: "",
    });
    this.getContents(this.state.path);
  }

  async openFolder(folderName) {
    const newPath = this.state.path + folderName + "/";
    await this.getContents(newPath);
    this.setState({ path: newPath });
  }

  async addFolder(folderName) {
    // console.log(folderName);
    await Store.receive(Actions.ADD_CKEDITOR_FOLDER, {
      root: this.state.path,
      folderName: folderName,
    });
    await this.getContents(this.state.path);
    // this.setState({ contents: contents });
  }

  handleOk() {
    this.setState({
      visible: false,
    });
    const imageLink = `https://indy4.s3.amazonaws.com/ckeditor/thumb/${this.state.selectedImage}`;
    // console.log(imageLink);
    // let imageLink = 'https://i.pinimg.com/originals/d9/4a/49/d94a495eca526d82ebbe0640aea413a9.jpg';
    this.state.insertImage(imageLink);
  }

  handleCancel(e) {
    this.setState({
      visible: false,
    });
  }

  async goToFolder(index) {
    let newPath = this.state.path.split("/").slice(0, index).join("/");
    newPath = newPath ? newPath + "/" : newPath;
    this.setState({ path: newPath });
    await this.getContents(newPath);
  }

  async deleteObject(key) {
    // console.log(key);
    await Store.receive(Actions.DELETE_CKEDITOR_OBJECT, { key: key });
    await this.getContents(this.state.path);
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  async selectImage(filePath) {
    // console.log("selected image : " + filePath)
    this.setState({ selectedImage: filePath });
  }

  // showModal = () => {
  //   this.setState({
  //     visible: true,
  //   });
  //   console.log("path: "+this.state.path);
  //   this.getContents(this.state.path);
  // };

  // handleOk = e => {
  //   // console.log(e);
  //   this.setState({
  //     visible: false,
  //   });
  //   let imageLink = 'https://i.pinimg.com/originals/d9/4a/49/d94a495eca526d82ebbe0640aea413a9.jpg';
  //   this.props.insertImage(imageLink);
  // };

  // handleCancel = e => {
  //   // console.log(e);
  //   this.setState({
  //     visible: false,
  //   });
  // };

  render() {
    const breadcrumb = (
      <Breadcrumb id={"ckeditor-breadcrumb"}>
        <Breadcrumb.Item onClick={(e) => this.goToFolder(0)}>
          <a>
            <HomeOutlined />
          </a>
        </Breadcrumb.Item>
        {this.state.path.split("/").map((item, index) => (
          <Breadcrumb.Item onClick={(e) => this.goToFolder(index + 1)}>
            <a>{item}</a>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
    return (
      <div>
        <Button onClick={this.showModal} id={"ckeditor-modal-button"}>
          Insert Images
        </Button>
        <Modal
          centered
          title={breadcrumb}
          onBlur={() => console.log("blurring")}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={"80%"}
          footer={[
            <UploadImagesButton
              path={this.state.path}
              getContents={this.getContents}
            />,
            <Cancel onClick={this.handleCancel} />,
            <Insert
              selectedImage={this.state.selectedImage}
              onClick={this.handleOk}
            />,
          ]}
        >
          <div id={"ckeditor-modal"}>
            <div id={"ckeditor-previews"}>
              <Collapse // ghost
                defaultActiveKey={["1", "2"]}
              >
                <Collapse.Panel header="Folders" key="1" id={"folder-previews"}>
                  <AddFolder addFolder={this.addFolder} />
                  <List size={"small"}>
                    {this.state.contents.folders.map((folder) => (
                      <CKEditorFolderPreview
                        name={folder}
                        root={this.state.path}
                        openFolder={this.openFolder}
                        deleteObject={this.deleteObject}
                      />
                    ))}
                  </List>
                </Collapse.Panel>
                <Collapse.Panel header="Files" key="2" id={"img-previews"}>
                  <div className={"img-previews-child"}>
                    {this.state.contents.objects.map((obj) => (
                      <CKEditorImagePreview
                        name={obj}
                        root={this.state.path}
                        handleOk={this.handleOk}
                        selectImage={this.selectImage}
                        deleteObject={this.deleteObject}
                      />
                    ))}
                  </div>
                </Collapse.Panel>
              </Collapse>
            </div>
            <div id={"selected-img"}>
              <b>Selected Image:</b> {this.state.selectedImage}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CKEditorImageModal;

function UploadImagesButton({ path, getContents }) {
  return (
    <Button>
      <Upload
        multiple
        showUploadList={false}
        data={{ folder: path }}
        action={"/aws/upload-ckeditor-image"}
        onSuccess={async (res, file, xhr) => {
          // console.log("upload successful!");
          // console.log(res);
          // console.log(file);
          await getContents(path);
        }}
      >
        Upload Images
      </Upload>
    </Button>
  );
}

function Cancel({ onClick }) {
  return (
    <Button key="back" onClick={onClick}>
      Cancel
    </Button>
  );
}

function Insert({ selectedImage, onClick }) {
  return (
    <Button
      disabled={selectedImage === ""}
      key="submit"
      type="primary"
      onClick={onClick}
    >
      Insert
    </Button>
  );
}

function AddFolder(props) {
  return (
    <div id={"add-folder-input"}>
      <Input.Search
        placeholder="Create a new folder"
        enterButton={<FolderAddOutlined />}
        // size={"small"}
        onSearch={(value, event) => props.addFolder(value)}
      />
    </div>
  );
}
