import React, { Component } from 'react';
// import { Form, Input, Button } from 'antd';

import Store from '../Store.js';
import {ADD_EMAIL, GET_PAGE} from "../Actions";
import {pages} from "../pages";

// const layout = {
//   labelCol: { offset:6, span: 3 },
//   wrapperCol: { span: 6 },
// };
// const centerLayout = {
//   labelCol: { span: 0 },
//   wrapperCol: { span: 24 },
// };
class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      submitted: false,
      pageId: pages.subscribe.id,
      pageBody: "",
      loading: true
    }
    this.addSubscriber = this.addSubscriber.bind(this);
  }

  async componentDidMount() {
    const page = await Store.receive(GET_PAGE, { pageId: this.state.pageId })
    this.setState({
      pageBody: page.body,
      loading: false,
    });
  }
  
  async addSubscriber(values) {
    let subscriber = {
      first_name: values.first,
      last_name: values.last,
      email: values.email,
    }
    Store.receive(ADD_EMAIL, {email: subscriber})
      .catch((err) => {
        console.error(err);
        this.setState({ error: true })
      })
      .finally(() => this.setState({ submitted: true }))
  }

  render() {
    if (this.state.error) {
      return (
        <div id={"subscribe-page"} className="page-content">
          <p>
            sorry! there was an error while subscribing
          </p>
          <p>
            please try again later.
          </p>
        </div>
      );
    }
    return (
      <div id={"subscribe-page"} className="page-content">
        {
          this.state.submitted?
            <h2>Thanks! We've got your email</h2>
            :
            <>
              <h1>Give us your email!</h1>
              <div dangerouslySetInnerHTML={{__html: this.state.pageBody}}/>
              <iframe title='substack-embed' src="https://theindy.substack.com/embed" 
                      width="480" height="320"
                      style={{"border":"1px solid #EEE", "background":"white"}}
                      frameBorder="0" scrolling="no"></iframe>
              {/*<div className={"subscribe-form"}>*/}
                {/*<Form*/}
                {/*  onFinish={this.addSubscriber}*/}
                {/*  {...layout}>*/}
                {/*  <Form.Item label="first name" name="first">*/}
                {/*    <Input placeholder="bruno"/>*/}
                {/*  </Form.Item>*/}
                {/*  <Form.Item label="last name" name="last">*/}
                {/*    <Input placeholder="the bear"/>*/}
                {/*  </Form.Item>*/}
                {/*  <Form.Item label="email" name="email"*/}
                {/*             rules={[{*/}
                {/*               required: true,*/}
                {/*               message: 'please enter your email!',*/}
                {/*               type: 'email'*/}
                {/*             }]}>*/}
                {/*    <Input placeholder="first_last@gmail.com"/>*/}
                {/*  </Form.Item>*/}
                {/*  <Form.Item {...centerLayout}>*/}
                {/*    <Button type="primary" htmlType="submit">*/}
                {/*      subscribe*/}
                {/*    </Button>*/}
                {/*  </Form.Item>*/}
                {/*  <h2>Want the Indy delivered to your doorstep each week?</h2>*/}
                {/*  <a href="https://docs.google.com/forms/d/e/1FAIpQLSf-ljjGCIdkZidz0MQV7xPTpLVaNYaaIU6ocgEp9qZO4OzCkQ/viewform">*/}
                {/*    Sign up for a print subscription*/}
                {/*  </a>*/}
                {/*</Form>*/}
              {/*</div>*/}
            </>
        }
      </div>
    );
  }
}

export default Subscribe;
