import React, { Component } from 'react';
import Actions from '../Actions.js';
import Store from '../Store.js';
import { IssuePreviewCard } from '../Components/components';
import { Row, Col, Pagination } from 'antd';
import Loading from "../Components/Loading";

// number of issues that show up on a page, must be a multiple of numColumns (see below)
class Issues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      numIssues: 0,
      issues: [],
      issueCards: [],

      numColumns: 6, // numColumns * columnSpan must equal 24 (ex. 6*4=24)
      // why 24? bc of antdesign
      // aka numColumns must be a factor of 24
      numRows: 4, // no restraints on this number
      pageCache: new Map(),
    }

    // this.handlePagination = this.handlePagination.bind(this);
    this.fetchPage = this.fetchPage.bind(this);
  }

  async componentDidMount() {
    this.setState({
      numIssues: await Store.receive(Actions.GET_NUM_PUBLISHED_ISSUES),
    })
    this.fetchPage(1);
  }
 
  async fetchPage(page) {
    this.setState({ loading: true })
    if (this.state.pageCache.has(page)) {
      this.setState({ issues: this.state.pageCache.get(page)});
    } else {
      let ISSUES_TO_LOAD = this.state.numColumns * this.state.numRows;

      // const ISSUES_TO_LOAD = numColumns * numRows;
      const offset = (page-1)*ISSUES_TO_LOAD;
      const issues = await Store.receive(Actions.GET_PUBLISHED_ISSUES, { limit: ISSUES_TO_LOAD, offset: offset });
      this.state.pageCache[page] = issues;
      this.setState({
        issues : issues,
        issueCards: issues.map((issue) => {
          return (<Col span={4}><IssuePreviewCard {...issue}/></Col>)
        }),
        loading: false
      });
    }
  }

  render() {
    return (
      <div id={"issues-page"} className={"page-content"}>
        <h1>all issues</h1>
        {
          this.state.loading?
            <Loading/>
            :
            <>
              <Row gutter={[8,8]}>
                { this.state.issueCards.slice(0,6) }
              </Row>
              <Row gutter={[8,8]}>
                { this.state.issueCards.slice(6,12) }
              </Row>
              <Row gutter={[8,8]}>
                { this.state.issueCards.slice(12,18) }
              </Row>
              <Row gutter={[8,8]}>
                { this.state.issueCards.slice(18,24) }
              </Row>
            </>
        }
        <Pagination showQuickJumper
                    defaultPageSize={this.state.numRows * this.state.numColumns}
                    showSizeChanger={false}
                    defaultCurrent={1}
                    total={this.state.numIssues}
                    onChange={this.fetchPage}/>
      </div>
    ) 
  }
}

export default Issues;
