import React from "react";
import { Badge } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";

function CKEditorImagePreview(props) {
  const filePath = props.root + props.name;
  const imageLink = `https://indy4.s3.amazonaws.com/ckeditor/thumb/${filePath}`;
  let deleteBadge = (
    <CloseCircleTwoTone
      twoToneColor="#ff0000"
      onClick={() => props.deleteObject(filePath)}
    />
  );
  return (
    <Badge count={deleteBadge} size={"small"} className={"img-preview"}>
      <div
        onClick={() => props.selectImage(filePath)}
        onDoubleClick={() => {
          props.selectImage(filePath);
          props.handleOk();
        }}
      >
        <div className={"img-preview-img"}>
          <img alt={props.name} src={imageLink} />
        </div>
        <div className={"img-preview-name"}>{props.name}</div>
      </div>
    </Badge>
  );
}

export default CKEditorImagePreview;
