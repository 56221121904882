import React from 'react';

export default function IssueCover({ document_number, size=imageSizes.ORIGINAL, imageStyle={} }) {
  const imageSrc = `http://image.issuu.com/${document_number}/jpg/page_1${size}.jpg`;
  return (
    <img src={imageSrc} style={imageStyle} alt={""} />
  );
}

export const imageSizes = {
  SMALL : "_thumb_small",
  MEDIUM : "_thumb_medium",
  LARGE : "_thumb_large",
  ORIGINAL : ""
};