import React  from 'react';
import { IssuePreviewCard, Loading } from '../Components/components'

export default function IssueCarousel(props) {
  return (
    <div id={"issue-carousel"} className={"horizontal-scroll-parent"}>
      <div className={"horizontal-scroll-child"}>
        {
          props.loading?
              <Loading/>
            :
            props.issues.map((issue) =>
              <IssuePreviewCard key={issue.id} {...issue} />
            )}
        <IssuePreviewCard key={0} moreIssues={true} />
      </div>
    </div>
  );
}