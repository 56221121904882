import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Card } from 'antd';
import {CollaboratorLinks} from "./components";
import {COLLABORATOR_TYPE} from "./CollaboratorLinks";

class FeaturedArticlePreview extends Component {
  render() {
    const articleLink = '/article/'+this.props.id;
    const image = this.props.first_image_url ?
      (<img alt="article" src={this.props.first_image_url}/>) :
      null;
    const title = (
      <Link to={articleLink}>
        <b>{this.props.title}</b>
        <p className='detail'>{this.props.subtitle}</p>
      </Link>
    );
    const content = (
      <CollaboratorLinks collaborators={this.props.byList}
                         type={COLLABORATOR_TYPE.AUTHOR}/>
    );
    return (
      <a className={"featured-article-preview-parent"} href={articleLink}>
        <Card
          hoverable
          className="featured-article-preview"
          cover={image}>
          <Card.Meta
            title={title}
            description={content}
          />
        </Card>
      </a>
    );

  }
}

// <Card.Meta
//   avatar={avatar}
//   title={this.props.title}
//   subtitle='subby'
//   description={content}
//   />
export default FeaturedArticlePreview;

// <div style={{'backgroundColor':'floralwhite', 'max-width':'400pt', 'margin':'auto'}}>
//
//   <div className="articlePreview-image">
//     <Link to={articleLink}>
//       { this.props.first_image_url?
//         <img src={this.props.first_image_url} width='100px' alt={this.props.title}/> : null }
//
//         </Link>
//       </div>
//       <div className="articlePreview-text">
//         <Link to={articleLink}>
//           <b>{this.props.title}</b>
//           <br></br>
//           {this.props.subtitle}
//         </Link>
//         <br></br>
// <Link to={'/section='+this.props.category+'/0'}>
//   {this.props.category}
// </Link>
// , Published {moment(this.props.published_at).format("MMMM Do, YYYY")}
// <br></br>
// By {this.props.by}
//       </div>
//     </div>
