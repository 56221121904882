import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Store from "../Store";
import {SEARCH_ARTICLES} from "../Actions";
import {ArticlePreview,Loading} from "../Components/components";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      queryParams: queryString.parse(this.props.location.search),
      loading: true,
    }
  }

  componentDidMount() {
    this.getSearchResults();
  }

  async getSearchResults() {
    const results = await Store.receive(SEARCH_ARTICLES, {query:this.state.queryParams.query});
    this.setState({
      articles: results,
      loading: false
    })
  }

  render() {
    return (
      <div id={"search-page"} className={"page-content"}>
        {
          this.state.loading?
            <h1>searching...</h1>
            :
            <h1>search results for <i>{this.state.queryParams.query}</i></h1>
        }
        {
          this.state.loading?
            <Loading/>
            :
            this.state.articles.map((article) =>
              <ArticlePreview key={article.id} {...article}/>
            )
        }
      </div>
    );
  }
}

export default withRouter(Search);
