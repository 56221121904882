import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class IssuuReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issueId: this.props.match.params.id,
      issue: {},
    };
  }

  componentDidMount() {
    this.fetchIssue();
  }

  async fetchIssue() {
    await fetch(`/db/issues/${this.state.issueId}`)
    .then(res => res.json())
    .then(res => this.setState({
      issue: res
    }));
  }

  render() {
    const embed = "<embed flashVars=\"mode=embed&amp;showFlipBtn=true&amp;proShowMenu=true&amp;documentId="+this.state.issue.documentId+"&amp;docName=fullbook_lowres_3&amp;username=theindy&amp;et=1275935260482&amp;er=59\" type=\"application/x-shockwave-flash\" src=\"https://static.issuu.com/webembed/viewers/style1/v1/IssuuViewer.swf\" allowFullScreen=\"true\" menu=\"false\" id=\"2_embed\" wmode=\"transparent\" width=\"100%\" height=\"1000px\"></embed>";
    return (
      <div dangerouslySetInnerHTML={{__html: embed}}/ >
    );
  }
}
//
// <object style={{width:'100%', height:'1000px'}} data="https://static.issuu.com/webembed/viewers/style1/v1/IssuuViewer.swf" type="application/x-shockwave-flash">
// <param name="allowfullscreen" value="true" />
// <param name="menu" value="false" />
// <param name="allowscriptaccess" value="always" />
// <param name="src" value="http://static.issuu.com/webembed/viewers/style1/v1/IssuuViewer.swf" />
// <param name="flashvars" value="mode=embed&amp;viewMode=presentation&amp;layout=http%3A%2F%2Fskin.issuu.com%2Fv%2Flight%2Flayout.xml&amp;backgroundColor=FFFFFF&amp;showFlipBtn=true&amp;documentId=200501172050-783243c2aa999e960e89b330f54d7266&amp;docName=40.10&amp;username=theindy&amp;loadingInfoText=Zeiss%20nuovo%20cannocchiale%20v8%20ita%20flyer%202014&amp;et=1397564036921&amp;er=41"/>
// </object>
//
// <embed
// flashVars="mode=embed&amp;showFlipBtn=true&amp;proShowMenu=true&amp;documentId=200501172050-783243c2aa999e960e89b330f54d7266&amp;docName=fullbook_lowres_3&amp;username=theindy&amp;et=1275935260482&amp;er=59"
// style={{width:'100%', height:'1000px'}}
// type="application/x-shockwave-flash"
// src="https://static.issuu.com/webembed/viewers/style1/v1/IssuuViewer.swf"
// allowFullScreen="true" menu="false"
// id="2_embed"
// wmode="transparent"
// width="300"
// height="193">
// </embed>

export default withRouter(IssuuReader);
// <embed  type="application/x-shockwave-flash" src="https://static.issuu.com/webembed/viewers/style1/v1/IssuuViewer.swf" allowfullscreen="true" menu="false" flashvars="mode=embed&amp;layout=http%3A%2F%2Fskin.issuu.com%2Fv%2Flight%2Flayout.xml&amp;showFlipBtn=true&amp;proShowMenu=true&amp;documentId=100318163929-24d2670dcbd84b368afec3f0ee73325c&amp;docName=fullbook_lowres_3&amp;username=stratton&amp;loadingInfoText=Learning%20By%20Design&amp;et=1275935260482&amp;er=59" id="2_embed" wmode="transparent" width="300" height="193">
// </embed>
//
// <object
// movie="http://static.issuu.com/webembed/viewers/style1/v2/IssuuReader.swf?mode=mini&documentId=200501172050-783243c2aa999e960e89b330f54d7266"
// allowfullscreen="true"
// menu="false"
// wmode="transparent">
// <embed src="http://static.issuu.com/webembed/viewers/style1/v2/IssuuReader.swf" type="application/x-shockwave-flash" allowfullscreen="true" menu="false" wmode="transparent" flashvars="mode=mini&documentId=200501172050-783243c2aa999e960e89b330f54d7266" />
// </object>
// <iframe width="525" height="679" src="//e.issuu.com/legacy.html?documentId=200501172050-783243c2aa999e960e89b330f54d7266&u=ds_demo" allowfullscreen></iframe>
// <iframe width="525" height="679" src="//e.issuu.com/legacy.html?documentId=200501172050-783243c2aa999e960e89b330f54d7266&pageNumber=2&viewMode=presentation&backgroundColor=00ff00&u=ds_demo" frameborder="0" allowfullscreen></iframe>
// <iframe
// height="1000px"
// width="100%"
// allow="fullscreen"
// allowfullscreen
// menu
// wmode="transparent"
// movie={
//   `http://static.issuu.com/webembed/viewers/style1/v2/IssuuReader.swf?mode=mini&documentId=${docid}`
// }>
// <embed src="http://static.issuu.com/webembed/viewers/style1/v2/IssuuReader.swf" type="application/x-shockwave-flash" allowfullscreen="true" menu="false" wmode="transparent" flashvars={`mode=mini&documentId=${docid}`} />
// </iframe>
// <iframe height="1000px" width="100%" allowfullscreen allow="fullscreen"
// src=
//   "http://static.issuu.com/webembed/viewers/style1/v2/IssuuReader.swf"
//
// type="application/x-shockwave-flash" allowfullscreen="true" menu="false" wmode="transparent" flashvars={`mode=mini&documentId=${docid}`}></iframe>
// <iframe height="1000px" width="100%" allowfullscreen allow="fullscreen" src={`http://static.issuu.com/webembed/viewers/style1/v2/IssuuReader.swf`}></iframe>
//   <iframe height="1000px" width="100%" allowfullscreen allow="fullscreen" src={`//e.issuu.com/embed.html?d=orange_juice_issue_1_b0ceb817d77d59&u=ds_demo`}></iframe>
//     <iframe src="//e.issuu.com/issuu-reader3-embed-files/latest/preview.html?data=%7B%22userDisplayName%22%3A%22College%20Hill%20Independent%22%2C%22title%22%3A%22The%20College%20Hill%20Independent%20Vol.%2040%20Issue%2010%22%2C%22backgroundColor%22%3A%22%23353535%22%2C%22originalFileId%22%3A%2281145532%22%7D" frameborder="0" height="100%" width="100%">
//     </iframe>
//     <iframe src="http://static.issuu.com/widgets/shelf/index.html?folderId=FOLDERIDamp;theme=theme1&amp;rows=1&amp;thumbSize=large&amp;roundedCorners=true&amp;showTitle=true&amp;showAuthor=false&amp;shadow=true&amp;effect3d=true" frameborder="0" marginwidth="0" marginheight="0" scrolling="no" width="100%" height="200">
//     </iframe>
//     <iframe src="http://static.issuu.com/widgets/shelf/index.html?folderId=FOLDERIDamp;theme=theme1&amp;rows=1&amp;thumbSize=large&amp;roundedCorners=true&amp;showTitle=true&amp;showAuthor=false&amp;shadow=true&amp;effect3d=true" frameborder="0" marginwidth="0" marginheight="0" scrolling="no" width="100%" height="200"></iframe>
//     <iframe src="https://issuu.com/theindy/docs/40.10/s/10654837" frameborder="0" style={{"overflow":"hidden","height":"105%","width":"105%","position":"absolute"}} height="100%" width="100%">
//     </iframe>
