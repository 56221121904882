import React, { Component } from 'react';
import Store from '../Store.js';
import {GET_LIST, GET_LISTS, GET_MOST_RECENT_LIST, GET_NUM_LISTS} from "../Actions";
import { Table } from "antd";
import {withRouter} from "react-router-dom";
import Loading from "../Components/Loading";

const LIST_LIMIT = 10;

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listId: this.props.match.params.id,
      list: {},
      lists: [],

      numLists: 0,
      pageCache: new Map(),
      
      loading: true
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    if (this.state.listId) {
      this.setState({
        list: await Store.receive(GET_LIST, {listId: this.state.listId}),
      })
    } else {
      this.setState({
        list: await Store.receive(GET_MOST_RECENT_LIST),
      })
    }
    this.setState({
      numLists: await Store.receive(GET_NUM_LISTS),
    })
    this.fetchPage(1);
  }

  async fetchPage(page) {
    this.setState({ loading: true })
    if (this.state.pageCache.has(page)) {
      this.setState({
        lists: this.state.pageCache.get(page),
        loading: false,
      });
    } else {
      const offset = (page-1)*LIST_LIMIT;
      const lists = await Store.receive(GET_LISTS, { offset: offset });
      this.state.pageCache[page] = lists;
      this.setState({
        lists : lists,
        loading: false
      });
    }
  }

  render() {
    const listColumns = [
      {
        title: "Previous Bulletin Boards",
        dataIndex: "created_at"
      }
    ]
    const paginationConfig = {
      showQuickJumper: true,
      defaultPageSize: LIST_LIMIT,
      showSizeChanger: false,
      defaultCurrent: 1,
      total: this.state.numLists,
      onChange: this.fetchPage
    }
    if (this.state.loading) {
      return (
        <div>
          <Loading/>
        </div>
      )
    }
    return (
      <div className="page-content">
        <h1>the bulletin board</h1>
        <img src={this.state.list.awsURL} alt="most recent list" width="100%"/>
        <Table columns={listColumns}
               dataSource={this.state.lists}
               size={"small"}
               pagination={paginationConfig}
               onRow={(list, rowIndex) => {
                 return {
                   onClick: (event) => { window.location = `/list/${list.id}` }, // click row
                 };
               }}/>
      </div>
    );
  }
}

export default withRouter(List);
