import React, { Component } from 'react';

class DBEntry extends Component {
  render() {
    return (
      <div style={{'background-color':'green', 'max-width':'400pt', 'margin':'auto'}}>
      <p>
      <b>id:</b>{ this.props.entry.id }
      <br></br>
      <b>name:</b>{ this.props.entry.name }
      </p>
      </div>
    );
  }
}

export default DBEntry;
