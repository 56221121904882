import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Issue } from '../Components/components';
import Actions from '../Actions.js';
import Store from '../Store.js';


class PrevIssue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issueId: this.props.match.params.issueId,
      issue: {},
      featuredArticles:[],
      fte: {},
      regularArticles:[],
      loading: true,
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })

    const [ issue, issueArticles ] = await Promise.all([
      Store.receive(Actions.GET_ISSUE_FROM_ID, {issueId : this.state.issueId}),
      Store.receive(Actions.GET_ARTICLES_FROM_ISSUE, {issueId : this.state.issueId})
    ]);

    this.setState({
      issue: issue,
      fte: issueArticles.fte,
      featuredArticles: issueArticles.featuredArticles,
      regularArticles: issueArticles.regularArticles
    });

    this.setState({ loading: false })
  }

  render() {
    return (
      <div>
        <Issue loading={this.state.loading}
               issue={this.state.issue}
               fte={this.state.fte}
               featuredArticles={[]}
               regularArticles={this.state.regularArticles} />
      </div>
    )
  }
}

export default withRouter(PrevIssue);
