import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { ArticlePreview, Loading } from '../Components/components'
import { Pagination } from 'antd';

const ARTICLES_TO_LOAD = 20;

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getPage: this.props.getPage,

      articlePreviews: [],
      pageCache: new Map(),

      loading: "false"
    }

    this.fetchPage = this.fetchPage.bind(this);
  }

  componentDidMount() {
    this.fetchPage(1);
  }

  async fetchPage(page) {
    this.setState({ loading: true })
    if (this.state.pageCache.has(page)) {
      this.setState({ articlePreviews: this.state.pageCache.get(page)});
    } else {
      const offset = (page-1)*ARTICLES_TO_LOAD;
      const articles = await this.state.getPage(offset);
      this.state.pageCache[page] = articles;
      this.setState({
        articlePreviews : articles,
        loading: false
      });
    }
  }


  render() {
    return (
      <div>
        {
          this.state.loading?
            <Loading/>
            :
            this.state.articlePreviews.map((article) => <ArticlePreview key={article.id} {...article}/>)
        }
        <Pagination showQuickJumper
                    defaultPageSize={ARTICLES_TO_LOAD}
                    showSizeChanger={false}
                    defaultCurrent={1}
                    total={this.props.numArticles}
                    onChange={this.fetchPage}/>
      </div>
    );
  }
}

export default withRouter(Section);
