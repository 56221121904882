import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  DatePicker,
  message,
} from "antd";
import { Editor } from "../Components/components";
import { ADMIN_SECTIONS } from "../sections";
import Store from "../Store.js";
import {
  DELETE_ARTICLE,
  GET_ARTICLE,
  UPDATE_ARTICLE,
  GET_ISSUES_TO_SELECT,
} from "../AdminActions";
const moment = require("moment");

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 6 },
};
const wideLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};
const centerLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articleId: this.props.match.params.id,
      articleBody: "",
      issues: [],
      formRef: React.createRef(), // this is what allows us to populate forms

      loading: true,
    };

    this.updateArticle = this.updateArticle.bind(this);
    this.grabFirstImage = this.grabFirstImage.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.deleteArticle = this.deleteArticle.bind(this);
  }

  componentDidMount() {
    this.fetchArticle();
  }

  async fetchArticle() {
    this.setState({ loading: true });

    const [article, issues] = await Promise.all([
      Store.adminReceive(GET_ARTICLE, { articleId: this.state.articleId }),
      Store.adminReceive(GET_ISSUES_TO_SELECT),
    ]);
    // const article = await Store.adminReceive(GET_ARTICLE, { articleId:this.state.articleId });
    this.setState({
      article: article,
      articleBody: article.body,
      issues: issues,
    });
    // get all the issues to have it appear in the select Button
    this.state.formRef.current.setFieldsValue({
      title: this.state.article.title,
      subtitle: this.state.article.subtitle,
      by: this.state.article.by,
      illustrator: this.state.article.illustrator,
      section: this.state.article.category,
      published: this.state.article.published,
      featured: this.state.article.featured,
      published_at: moment(this.state.article.published_at),
      issueId: this.state.article.issue_id,
      excerpt: this.state.article.excerpt,
      pullquote: this.state.article.pullquote,
    });
    this.setState({ loading: false });
  }

  handleEditorChange(event, editor) {
    const data = editor.getData();
    this.setState({ articleBody: data });
  }

  updateArticle(values) {
    const imageSrc = this.grabFirstImage();
    values["first_image_url"] = imageSrc;
    values["articleId"] = this.state.articleId;
    values["body"] = this.state.articleBody;
    values["published_at"] = moment(values.published_at).format("YYYY-MM-DD");
    Store.adminReceive(UPDATE_ARTICLE, { article: values })
      .then(() => message.success("Successfully updated this article!"))
      .catch((e) => message.error("There was an issue while updating!"));
  }

  deleteArticle() {
    Store.adminReceive(DELETE_ARTICLE, { articleId: this.state.articleId })
      .then(() => (window.location.href = `/admin/articles`))
      .then(() => message.success("Successfully deleted the article!"))
      .catch((e) => message.error("Could not delete this article!"));
  }

  grabFirstImage() {
    let images = document.getElementsByTagName("img");
    if (images.length > 0) {
      const firstImage = images[0];
      const src = firstImage.src;
      return src;
    }
    return "";
  }

  render() {
    return (
      <div id={"article-page"}>
        <Form
          {...wideLayout}
          scrollToFirstError
          initialValues={{ published_at: moment() }}
          onFinish={this.updateArticle}
          ref={this.state.formRef}
        >
          <Form.Item label="Title" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Subtitle" name="subtitle">
            <Input />
          </Form.Item>
          <Form.Item label="By" name="by">
            <Input />
          </Form.Item>
          <Form.Item label="Illustrator" name="illustrator">
            <Input />
          </Form.Item>
          <Form.Item label="Section" name="section" {...layout}>
            <Select>
              {ADMIN_SECTIONS.map((section) => {
                return (
                  <Select.Option
                    key={section.adminName}
                    value={section.adminName}
                  >
                    {section.adminName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="Excerpt" name="excerpt">
            <Input />
          </Form.Item>
          <Form.Item label="Pullquote" name="pullquote">
            <Input />
          </Form.Item>
          <Form.Item label="Article" {...wideLayout}>
            <Editor
              onChange={this.handleEditorChange}
              data={this.state.articleBody}
            />
          </Form.Item>
          <Form.Item label="Publish?" name="published" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item label="Featured" name="featured" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <Form.Item label="Issue" name="issueId" {...layout}>
            <Select>
              {this.state.issues.map((issue) => (
                <Select.Option value={issue.id} key={issue.id}>
                  {issue.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Date published" name="published_at">
            <DatePicker />
          </Form.Item>
          <div id={"footer"}>
            <Form.Item className={"admin-article-button"} {...centerLayout}>
              <Button type="primary" htmlType="submit">
                Update Article
              </Button>
              <Button type="secondary" onClick={this.deleteArticle}>
                Destroy
              </Button>
              <Button type="secondary">
                <a href={`/article/${this.state.articleId}`}>Preview</a>
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }
}

export default withRouter(Article);
