import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Input } from "antd";
import { Responsive } from "responsive-react";
import { MENU_SECTIONS, ARCHIVE_SECTIONS } from "../sections";
import {
  FacebookFilled,
  InstagramFilled,
  TwitterOutlined,
  DownOutlined,
  HomeFilled
} from "@ant-design/icons";
// import twitter from "../../public/images/twitter.png";
// import facebook from "../../public/images/facebook.png";
import logo from "../../public/images/indylogo.png";
import asterisk from "../../public/images/indyasterisk.png";
import weblogo from "../../public/images/indy-logo-small-cropped.jpg";
import "../../public/stylesheets/global.less";

class Header extends Component {
  render() {
    return (
      <div>
        <Responsive displayIn={["Mobile", "Tablet"]}>
          <MobileHeader />
        </Responsive>
        <Responsive displayIn={["Laptop"]}>
          <WebHeader />
        </Responsive>
      </div>
    );
  }
}

function MobileHeader() {
  const title = (
    <Link to="/">
      <img
        alt="Indy logo"
        src={weblogo}
        style={{
          objectFit: "contain",
          display: "inline",
          height: "40px",
          align: "center",
        }}
      />
    </Link>
  );
  return (
    <Menu
      id="mobile-header"
      mode="inline"
      defaultSelectedKeys={["general", "sections"]}
    >
      <Menu.SubMenu title={title}>
        <Menu.SubMenu key="sections" title="sections">
          {sectionsMenuItems}
          {archiveSubMenu}
        </Menu.SubMenu>
        <Menu.SubMenu key="about" title="about">
          {generalMenu}
          <div id={"social-links"}>{socialLinks}</div>
        </Menu.SubMenu>
        <div id={"searchbar"}>
          <Searchbar />
        </div>
      </Menu.SubMenu>
    </Menu>
  );
}

function WebHeader() {
  return (
    <div id={"web-header"}>
      {/* <TopNav /> */}
      <Menu id="header-top-nav" mode="horizontal">
        {/* {socialLinks} */}
        <div className="social-links">
        <Menu.Item key="home">
          <a href="/">
            <HomeFilled />
          </a>
        </Menu.Item>
          <Menu.Item key="t">
            <a href="https://twitter.com/theindy_tweets">
              <TwitterOutlined />
            </a>
          </Menu.Item>
          <Menu.Item key="fb">
            <a href="https://www.facebook.com/collegehillindependent/">
              <FacebookFilled />
            </a>
          </Menu.Item>
          <Menu.Item key="insta">
            <a href="https://www.instagram.com/theindypvd/">
              <InstagramFilled />
            </a>
          </Menu.Item>
          <Menu.Item key="search">
            {/* <span id={"spacer"}></span> */}
            <Searchbar style={{ marginTop: "10px" }} />
          </Menu.Item>
          
        </div>

        <div className="general-menu">
        {generalMenu}

        <Menu.SubMenu key="sections" title={sectionsTitle}>
          {sectionsMenuItems}
          {archiveSubMenu}
        </Menu.SubMenu>
        </div>
      </Menu>

      <Link to="/">
        <img alt="Indy logo" src={logo} />
        {/*<h1>THE COLLEGE HILL INDEPENDENT</h1>*/}
      </Link>
      
      {/* <Sections /> */}
      
    </div>
  );
}

function onSearch(value) {
  window.location.href = `/search?query=${value}`;
}

function Searchbar() {
  return (
    <Input.Search onSearch={onSearch} style={{ width: 200, marginTop: 7 }} />
  );
}

const socialLinks = (
  <div className="social-links">
    <Menu.Item key="home">
      <a href="/">
        <TwitterOutlined />
      </a>
    </Menu.Item>
    <Menu.Item key="t">
      <a href="https://twitter.com/theindy_tweets">
        <TwitterOutlined />
      </a>
    </Menu.Item>
    <Menu.Item key="fb">
      <a href="https://www.facebook.com/collegehillindependent/">
        <FacebookFilled />
      </a>
    </Menu.Item>
    <Menu.Item key="insta">
      <a href="https://www.instagram.com/theindypvd/">
        <InstagramFilled />
      </a>
    </Menu.Item>
  </div>
);

const generalMenu = [
  <Menu.Item key="about">
    <Link to="/about">about</Link>
  </Menu.Item>,
  <Menu.Item key="apply">
    <Link to="/apply">apply</Link>
  </Menu.Item>,
  <Menu.Item key="subscribe">
    <Link to="/subscribe">subscribe</Link>
  </Menu.Item>,
  <Menu.Item key="alumni">
    <Link to="/alumni">alumni</Link>
  </Menu.Item>,
  <Menu.Item key="donate">
    <Link to="/donate">donate</Link>
  </Menu.Item>,
];

const sectionsMenuItems = MENU_SECTIONS.map((section) => {
  return (
    <Menu.Item key={section.adminName}>
      <a href={`/section=${section.adminName}`}>{section.name.toLowerCase()}</a>
    </Menu.Item>
  );
});
// sectionsMenuItems.push(
//   <Menu.Item key={"list"}>
//     <a href={`/list`}>bulletin board</a>
//   </Menu.Item>
// );

const seriesTitle = (
  <a href="/" className="ant-dropdown-link">
    series{" "}
    <Responsive displayIn={["Laptop"]}>
      <DownOutlined />
    </Responsive>
  </a>
);
const sectionsTitle = (
  <a href="/" className="ant-dropdown-link">
    sections{" "}
    <Responsive displayIn={["Laptop"]}>
      <DownOutlined />
    </Responsive>
  </a>
);
const archiveSubMenu = (
  <Menu.SubMenu key={"archive"} title={"archive"}>
    {ARCHIVE_SECTIONS.map((section) => {
      return (
        <Menu.Item key={section.adminName}>
          <a href={`/section=${section.adminName}`}>
            {section.name.toLowerCase()}
          </a>
        </Menu.Item>
      );
    })}
  </Menu.SubMenu>
);
export default Header;
