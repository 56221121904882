import React, { Component } from 'react';
import { Table, Spin } from 'antd';

import Store from '../Store.js';
import {GET_ALUMS} from "../AdminActions";
const { Column } = Table;

class Alums extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alumni: [],
      emails: [],
      loadingEmails: true,
      loadingTable: true
    }
  }

  async componentDidMount() {
    const alumni = await Store.adminReceive(GET_ALUMS);
    this.setState({
      alumni: alumni,
      emails: alumni.map(alum => { return alum.email }),
      loadingTable: false,
      loadingEmails: false,
    })
  }

  async fetchData () {
    const alumni = await Store.adminReceive(GET_ALUMS);
    this.setState({
      alumni: alumni,
      emails: alumni.map(alum => { return alum.email }),
      loadingTable: false,
      loadingEmails: false,
    })
  }

  render() {
    return (
      <div>
        <div>
          <h1>Alumni Email CSV.</h1>
          { this.state.loadingEmails ? <Spin style={{margin: '10px'}}/> : this.state.emails.join(", ") }
        </div>
        <br></br>
        <div>
          <h1>Alumni Data in Table Form.</h1>
          <Table
            dataSource={this.state.alumni}
            loading={this.state.loadingTable}
            size="small"
            pagination={{defaultPageSize:50}}>
            <Column title="Name" dataIndex="name" key="name" colSpan={1}
                    render= { (text) => text }/>
            <Column title="Email" dataIndex="email" key="email" colSpan={1}
                    render= { (text) => text }/>
            <Column title="Grad Year" dataIndex="grad_year" key="grad_year" colSpan={1}
                    render= { (text) => text }/>
            <Column title="Current Job" dataIndex="job_now" key="job_now" colSpan={1}
                    render= { (text) => text }/>
            <Column title="Staff Position" dataIndex="staff_position" key="staff_position" colSpan={1}
                    render= { (text) => text }/>
            <Column title="Location" dataIndex="where_you_live" key="where_you_live" colSpan={1}
                    render= { (text) => text }/>
          </Table>
        </div>
      </div>
    );
  }
}

export default Alums;
