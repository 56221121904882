import React, { Component } from 'react';
import { Editor } from '../Components/components';
import {Button, message, Select} from "antd";
import Store from '../Store.js';
import {GET_PAGE} from "../Actions";
import {pagesList} from "../pages";
import {UPDATE_PAGE} from "../AdminActions";

export default class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateDisabled: true,
      pageId: 0,
      pageBody: ""
    }
    this.onPageSelect = this.onPageSelect.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.updatePage = this.updatePage.bind(this);
  }

  async onPageSelect(value) {
    const page = await Store.receive(GET_PAGE, { pageId: value })
    this.setState({
      updateDisabled: false,
      pageId: value,
      pageBody: page.body
    })
  }

  handleEditorChange(event, editor) {
    const data = editor.getData();
    this.setState({ pageBody: data, });
  }

  updatePage() {
    // console.log(this.state.pageBody)
    Store.adminReceive(UPDATE_PAGE, { id: this.state.pageId, body: this.state.pageBody })
      .then(() => message.success("Successfully updated this page!"))
      .catch((e) => message.error("There was an issue while updating!"))
  }

  render() {
    return (
      <div id={"pages-page"}>
        <h2>Pages</h2>
        <div id={"select"}>
          Page:
          <Select placeholder="select a page"
                  onSelect={this.onPageSelect}
                  style={{ width: 200 }}>
            {
              pagesList.map((page) =>
                <Select.Option value={page.id} key={page.id}>
                  {page.title}
                </Select.Option>
              )
            }
          </Select>
        </div>
        <div id={"editor-parent"}>
          <Editor data={this.state.pageBody}
                  onChange={this.handleEditorChange}/>
        </div>
        <Button className={"admin-button"}
                type={"primary"}
                onClick={this.updatePage}
                disabled={this.state.updateDisabled}>
          update page
        </Button>
      </div>
    )
  }
}