import Alumni from './Alumni';
import Article from './Article';
import Articles from './Articles';
import Emails from './Emails';
import Home from './Home';
import Issue from './Issue';
import Issues from './Issues';
import Lists from './Lists';
import Pages from './Pages';

export default {
  Alumni,
  Article,
  Articles,
  Emails,
  Home,
  Issue,
  Issues,
  Lists,
  Pages
};
