import React, { Component } from 'react';

class Footer extends Component {

  render() {
    return (
      <div className='center'>
        <hr/>
        indy staff here
        <p> more info like how to contact us or smth </p>
      </div>
    );
  }
}

export default Footer;
