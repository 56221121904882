import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Store from "../Store";
import {GET_AUTHOR_ARTICLES, GET_NUM_AUTHOR_ARTICLES} from "../Actions";
import PaginatedArticleList from "../Components/PaginatedArticleList";

class Author extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numArticles: 0,
      author: this.props.match.params.author, // grabbing this from the URL
    }
  }

  async componentDidMount() {
    this.setState({
      numArticles: await Store.receive(GET_NUM_AUTHOR_ARTICLES, {author: this.state.author})
    })
  }

  getPage(offset) {
    return Store.receive(GET_AUTHOR_ARTICLES, {author:this.state.author, offset: offset});
  }

  render() {
    return (
      <div className={"page-content"}>
        <h2>articles written by {this.state.author}</h2>
        <PaginatedArticleList numArticles={this.state.numArticles}
                              getPage={(offset) => this.getPage(offset)} />
      </div>
    );
  }
}

export default withRouter(Author);
