import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import {Form, Input, Button, Checkbox, DatePicker, Card, Upload, message} from 'antd';
import { IssueCover, Loading } from '../Components/components';

import Store from '../Store.js';
import {UploadOutlined} from "@ant-design/icons";
import { DELETE_ISSUE, GET_ISSUE_FROM_ID, UPDATE_ISSUE, GET_ARTICLES_FROM_ISSUE } from "../AdminActions";
import {imageSizes} from "../Components/IssueCover";

const moment = require('moment');

const layout = {
  labelCol: { offset: 5, span: 3 },
  wrapperCol: { span: 8 },
};

const centerLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

class Issue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      official: props.official,

      issueId: props.match.params.id,
      issue: {},
      articles: [],

      formRef: React.createRef(),
      loading: true,
    };

    this.updateIssue = this.updateIssue.bind(this);
    this.destroyIssue = this.destroyIssue.bind(this);
  }

  componentDidMount() {
    this.fetchIssue();
  }

  async fetchIssue() {
    this.setState({ loading: true });
    const req = {
      official: this.state.official,
      issueId: this.state.issueId
    };
    const [ issue, articles ] = await Promise.all([
      Store.adminReceive(GET_ISSUE_FROM_ID, req),
      Store.adminReceive(GET_ARTICLES_FROM_ISSUE, req)
    ]);
    this.setState({
      issue: issue,
      articles: articles,
      loading: false
    });
    this.setFormValues(issue);
  }

  async updateIssue(values) {
    const old_issue_url_slug = this.state.issue.url_slug;
    values.url_slug_updated = values.url_slug !== old_issue_url_slug;
    const newIssue = {
      id: this.state.issueId,
      title: values.title,
      date: moment(values.date).format("YYYY-MM-DD"),
      url_slug: values.url_slug,
      published: values.published,
      document_number: values.document_number,
      url_slug_updated: values.url_slug_updated
    };
    try {
      const issue = await Store.adminReceive(UPDATE_ISSUE, { issue: newIssue, official: this.state.official });
      this.setState({ issue: issue });
      this.setFormValues(this.state.issue);
      message.success("Successfully updated this issue!")
    } catch (e) {
      message.error("There was an issue while deleting!")
    }
    // Store.adminReceive(UPDATE_ISSUE, { issue: newIssue, official: this.state.official })
    //   .then((res) => this.setState({ issue: res }))
    //   .then(() => message.success("Successfully updated this issue!"))
    //   .catch((e) => message.error("There was an issue while deleting!"))
  }

  setFormValues(issue) {
    this.state.formRef.current.setFieldsValue({
      title: issue.title,
      url_slug: issue.url_slug,
      published: issue.published,
      date: moment(issue.date),
      document_number: issue.document_number,
    });
  }

  async destroyIssue() {
    const shouldDestroy = window.confirm('Are you ABSOLUTELY POSITIVELY SURE that you want to delete this issue?');
    if (shouldDestroy) {
      const req = {
        official: this.state.official,
        issueId: this.state.issueId
      };
      Store.adminReceive(DELETE_ISSUE, req)
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          if (this.state.official) {
            window.location.href = `/admin/official-issues`;
          } else {
            window.location.href = `/admin/issues`;
          }
        })
    }
  }

  render() {
    return (
      <div id={"issue-page"}>
        <Card title="how to edit issue">
          <p>Issue editing pretty much follows the same way it did on the old indy</p>
          <p>Note:
            You're editing an issue. Make sure not to edit the document number field unless you know what you're doing. Although the application will overwrite it on save most of the time anyway.
            The url-slug needs to be pulled from the issuu url. For example, in this url: "http://issuu.com/theindy/docs/vo29_no1", just grab the "vo29_no1" and enter it into the url_slug field.
          </p>
          <p>
            <b>V SIMPLE STEP BY STEP</b>
            <ol>
              <li>get url_slug from the most recent upload on issuu.com. the url_slug is what comes after "/docs", aka: if issuu link is "https://issuu.com/theindy/docs/vol_41_iss_5" then url_slug is <b>"vol_41_iss_5"</b> </li>
              <li>press "update issue" -> the page should refresh w the cover and the title</li>
              <li>save the cover image, press "upload issue cover", and select the image</li>
              <li>refresh <a href="http://www.theindy.org/admin/issues"><u>this page</u></a> and see that the issue is there</li>
              <li>go upload the articles and mark them as "published" and assign their issue</li>
              <li>once done with all the articles, come back to this page, and update the issue as published</li>
              <li>press "update issue"</li>
              <li>go check <a href="http://www.theindy.org/"><u>the home page</u></a> and make sure that nothing is broken pls</li>
            </ol>
          </p>
        </Card>
        <div id={"issue-form"}>
          <Form onFinish={this.updateIssue}
                ref={this.state.formRef}
                initialValues={{ date : moment()}}
                {...layout}>
            <Form.Item label="title" name="title">
              <Input placeholder="this will get filled automatically"/>
            </Form.Item>
            <Form.Item label="url slug" name="url_slug">
              <Input/>
            </Form.Item>
            <Form.Item label="document number" name="document_number">
              <Input placeholder={"this will get filled automatically"}/>
            </Form.Item>
            <Form.Item label="published date" name="date">
              <DatePicker/>
            </Form.Item>
            <Form.Item label="published?" name="published" valuePropName="checked">
              <Checkbox/>
            </Form.Item>
            <Form.Item className={"admin-article-button"} {...centerLayout}>
              <Button type="primary" htmlType="submit">
                Update Issue
              </Button>
              <Button icon={<UploadOutlined />}>
                <Upload action={`/db/aws/upload-issue-cover/${this.state.issueId}`}>
                  Upload Issue Cover
                </Upload>
              </Button>
              <Button onClick={this.destroyIssue}>
                Destroy
              </Button>
            </Form.Item>
          </Form>
        </div>
        {
          this.state.loading?
            <Loading/>
            :
            <div id={"issue-info"}>
              <IssueCover document_number={this.state.issue.document_number} size={imageSizes.ORIGINAL}/>
              <div id={"issue-articles"}>
                <h3>Articles:</h3>
                <ul>
                  {
                    this.state.articles.map((article) =>
                      <li><Link key={article.id} to={`/admin/article/${article.id}`}>
                        {article.title}
                      </Link></li>)
                  }
                </ul>
              </div>
            </div>
        }
      </div>
    );
  }
}


export default withRouter(Issue);

