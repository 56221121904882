import React, { Component } from 'react';
import { Table, Checkbox, Button } from 'antd';
import Store from '../Store';
import {ADD_ARTICLE, DELETE_ARTICLE, GET_ALL_ARTICLES, GET_NUM_ALL_ARTICLES} from '../AdminActions';
import {Link} from "react-router-dom";


const ARTICLES_TO_LOAD = 50;

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articlesLoading: true,

      numArticles: 0,
      articles: [],
      pageCache: new Map(),
    }

    this.fetchPage = this.fetchPage.bind(this);
  }

  async componentDidMount() {
    this.setState({
      numArticles: await Store.adminReceive(GET_NUM_ALL_ARTICLES)
    });
    this.fetchPage(1);
  }

  async addArticle() {
    Store.adminReceive(ADD_ARTICLE)
      .then(res => window.location.href = `/admin/article/${res.id}`);
  }

  async deleteArticle(articleId) {
    Store.adminReceive(DELETE_ARTICLE, { articleId: articleId })
      .then(window.location.reload());
  }

  async fetchPage(page) {
    this.setState({ articlesLoading: true })
    if (this.state.pageCache.has(page)) {
      this.setState({ articles: this.state.pageCache.get(page), articlesLoading: false});
    } else {
      const offset = (page-1)*ARTICLES_TO_LOAD;
      const articles = await Store.adminReceive(GET_ALL_ARTICLES, { offset: offset });
      this.state.pageCache[page] = articles;
      this.setState({
        articles : articles,
        articlesLoading: false
      });
    }
  }

  render() {

    const columns = [
      {
        title: "Edit",
        dataIndex: "id",
        key: "editButton",
        render: (id) => <Button href={`/admin/article/${id}`}>edit</Button>
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (title, entry) => <Link to={`/admin/article/${entry.id}`}>{title}</Link>
      },
      {
        title: "Author(s)",
        dataIndex: "by",
        key: "by"
      },
      {
        title: "Section",
        dataIndex: "category",
        key: "section"
      },
      {
        title: "Published",
        dataIndex: "published",
        key: "published",
        render: (published) => <Checkbox checked={published}/>
      },
      {
        title: "Featured",
        dataIndex: "featured",
        key: "featured",
        render: (featured) => <Checkbox checked={featured}/>
      },
      {
        title: "Date Published",
        dataIndex: "published_at",
        key: "publishedAt",
      },
      {
        title: "Delete",
        dataIndex: "id",
        key: "deleteButton",
        render: (id) => <Button onClick={() => this.deleteArticle(id)}>delete</Button>
      }
    ];
    const paginationConfig = {
      showQuickJumper: true,
      defaultPageSize: ARTICLES_TO_LOAD,
      showSizeChanger: false,
      defaultCurrent: 1,
      total: this.state.numArticles,
      onChange: this.fetchPage
    }
    return (
      <div>
        <h2>Articles</h2>
        <div className={"add"}>
          <Button className={"admin-button"} 
                  type="primary" 
                  onClick={this.addArticle}>
            add article
          </Button>
        </div>
        <Table columns={columns}
               dataSource={this.state.articles}
               size={"small"}
               pagination={paginationConfig} />
      </div>
    );
  }
}

export default Articles;