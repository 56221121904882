import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { DiscussionEmbed } from "disqus-react";

import { CollaboratorLinks, Loading } from "../Components/components";
import { COLLABORATOR_TYPE } from "../Components/CollaboratorLinks";

import { GET_ARTICLE } from "../Actions.js";
import Store from "../Store.js";

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      articleId: this.props.match.params.id,
      articleData: [],
      article: {},
    };

    const id = parseInt(this.state.articleId);
    if (!id) {
      window.location.href = "/";
    }
  }

  componentDidMount() {
    this.fetchArticle();
    // .then((r) => {this.setState({loading: false})});
  }

  async fetchArticle() {
    // this.setState({ loading: true });
    this.setState({
      article: await Store.receive(GET_ARTICLE, {
        articleId: this.state.articleId,
      }),
      loading: false,
    });
    this.resizeImages();
  }

  resizeImages() {
    let images = document.getElementsByTagName("img");
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      image.style = {};
      // image.style.width = "";
      // image.style.height = "";
    }
    // let body = article.body.replace(/(<[^>]+) style=".*?"/ig, '$1');
  }

  render() {
    const article = this.state.article;

    if (this.state.loading) {
      return (
        <div className={"page-content"}>
          <Loading />
        </div>
      );
    }
    return (
      <div id="article-page" className={"page-content"}>
        <div className={"article-header"}>
          <h1>{article.title}</h1>
          {article.subtitle ? <h2>{article.subtitle}</h2> : <></>}
          <CollaboratorLinks
            collaborators={article.byList}
            type={COLLABORATOR_TYPE.AUTHOR}
          />
          <CollaboratorLinks
            collaborators={article.illustratorList}
            type={COLLABORATOR_TYPE.ILLUSTRATOR}
          />
          <p>published {article.published_at}</p>
        </div>
        <div
          className={"article-content"}
          dangerouslySetInnerHTML={{ __html: article.body }}
        />
        <DiscussionEmbed
          shortname="theindyweb"
          // this is how the article is identified on disqus so the format can't be changed
          config={{ url: `http://www.theindy.org/${article.id}` }}
        />
      </div>
    );
  }
}

export default withRouter(Article);
