import React from "react";
import { Link } from "react-router-dom";
import { CollaboratorLinks } from "../Components/components";
import { Card } from "antd";
import { COLLABORATOR_TYPE } from "./CollaboratorLinks";

export default function ArticlePreview(props) {
  const articleLink = "/article/" + props.id;
  return (
    <a href={articleLink}>
      <Card className={"article-preview"} hoverable>
        {props.first_image_url ? (
          <div className={"article-image-parent"}>
            <div className={"article-image-child"}>
              <img src={props.first_image_url} alt={""} height={"170px"} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={"article-info"}>
          <Link to={articleLink}>
            <h2>
              <b>{props.title}</b>
            </h2>
            <h3>
              <b>{props.subtitle}</b>
            </h3>
          </Link>
          <div>
            <Link to={`/section=${props.category}/0`}>{props.category}</Link>
            {/* {Array.isArray(props.category) ? (
              props.category.map((section, index) => (
                <span key={section}>
                  <Link to={`/section=${section}/0`}>{section}</Link>
                  {index < props.category.length - 1 && " x "}
                </span>
              ))
            ) : (
              <Link to={`/section=${props.category}/0`}>{props.category}</Link>
            )} */}
            , Published {props.published_at}
            <CollaboratorLinks
              collaborators={props.byList}
              type={COLLABORATOR_TYPE.AUTHOR}
            />
          </div>
          <p>{props.body}</p>
        </div>
      </Card>
    </a>
  );
}
