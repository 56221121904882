import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyC7ylbR_HRf1R1u8X24d4p6KKuXyyKg0W0",
//   authDomain: "theindy-ae8de.firebaseapp.com",
//   projectId: "theindy-ae8de",

//   storageBucket: "theindy-ae8de.appspot.com",
//   messagingSenderId: "899186744534",
//   appId: "1:899186744534:web:370e9a0fc72b92752e3d77",
//   measurementId: "G-H2ZGZ5K6ZS",
// };

// export default app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);

// const laterEnv = firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP__ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// });

// // Initialize Firebase
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// require("firebase/auth");
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyC7ylbR_HRf1R1u8X24d4p6KKuXyyKg0W0",
  authDomain: "theindy-ae8de.firebaseapp.com",
  projectId: "theindy-ae8de",
  storageBucket: "theindy-ae8de.appspot.com",
  messagingSenderId: "899186744534",
  appId: "1:899186744534:web:370e9a0fc72b92752e3d77",
  measurementId: "G-H2ZGZ5K6ZS",
};

const app = firebase.initializeApp(firebaseConfig);
// Initialize Firebase
export default app;
export const auth = app.auth();

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
