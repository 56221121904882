module.exports = {
  GET_ARTICLE: "GET_ARTICLE",
  GET_SECTION_ARTICLES: "GET_SECTION_ARTICLES",
  GET_NUM_SECTION_ARTICLES: "GET_NUM_SECTION_ARTICLES",
  GET_AUTHOR_ARTICLES: "GET_AUTHOR_ARTICLES",
  GET_NUM_AUTHOR_ARTICLES: "GET_NUM_AUTHOR_ARTICLES",
  GET_ILLUSTRATOR_ARTICLES: "GET_ILLUSTRATOR_ARTICLES",
  GET_NUM_ILLUSTRATOR_ARTICLES: "GET_NUM_ILLUSTRATOR_ARTICLES",
  GET_ARTICLES_FROM_ISSUE: "GET_ARTICLES_FROM_ISSUE",
  SEARCH_ARTICLES: "SEARCH_ARTICLES",

  GET_ISSUE_FROM_ID: "GET_ISSUE_FROM_ID",
  GET_MOST_RECENT_ISSUE: "GET_MOST_RECENT_ISSUE",
  GET_PUBLISHED_ISSUES: "GET_PUBLISHED_ISSUES",
  GET_NUM_PUBLISHED_ISSUES: "GET_NUM_PUBLISHED_ISSUES",
  GET_NUM_ALL_ISSUES: "GET_NUM_ALL_ISSUES",


  // UPLOAD_LIST: "UPLOAD_LIST",
  GET_LIST: "GET_LIST",
  GET_LISTS: "GET_LISTS",
  GET_NUM_LISTS: "GET_NUM_LISTS",
  GET_MOST_RECENT_LIST: "GET_MOST_RECENT_LIST",
  // DELETE_LIST: "DELETE_LIST",

  // ADD_SUBSCRIBER: "ADD_SUBSCRIBER",
  ADD_EMAIL: "ADD_EMAIL",
  // GET_EMAILS: "GET_EMAILS",

  // GET_ALUMS: "GET_ALUMS",
  ADD_ALUM: "ADD_ALUM",

  GET_CKEDITOR_CONTENTS: "GET_CKEDITOR_CONTENTS",
  ADD_CKEDITOR_FOLDER: "ADD_CKEDITOR_FOLDER",
  UPLOAD_CKEDITOR_IMAGES: "UPLOAD_CKEDITOR_IMAGES",
  DELETE_CKEDITOR_OBJECT: "DELETE_CKEDITOR_OBJECT",

  GET_PAGE: "GET_PAGE",
};
