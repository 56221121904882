const pages = {
  about: {
    title: "about",
    id: 3,
  },
  donate: {
    title: "donate",
    id: 4,
  },
  apply: {
    title: "apply",
    id: 2,
  },
  alumni: {
    title: "alumni",
    id: 1,
  },
  subscribe: {
    title: "subscribe",
    id: 5,
  }
}

const pagesList = Object.keys(pages).map((page) => {
  return pages[page];
});

module.exports = {
  pages,
  pagesList
}
