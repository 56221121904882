import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "password"
    };
  }

  render() {
    // TODO: just move this into pages
    return (
      <div>
        <h1>admin side</h1>
      </div>
    );
  }
}

export default withRouter(Home);
