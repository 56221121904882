import React from "react";
import { List } from "antd";
import { DeleteTwoTone, FolderOutlined } from "@ant-design/icons";

function CKEditorFolderPreview(props) {
  const filePath = props.root + props.name + "/";
  const DeleteButton = (
    <a href="/">
      <DeleteTwoTone
        twoToneColor="#ff0000"
        onClick={(e) => props.deleteObject(filePath)}
      />
    </a>
  );
  return (
    <List.Item className={"folder-preview"} actions={[DeleteButton]}>
      <a onClick={() => props.openFolder(props.name)}>
        <FolderOutlined /> {props.name}
      </a>
    </List.Item>
  );
}

export default CKEditorFolderPreview;
