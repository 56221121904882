import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {GET_SECTION_ARTICLES, GET_NUM_SECTION_ARTICLES} from "../Actions";
import Store from '../Store.js';
import PaginatedArticleList from "../Components/PaginatedArticleList";

// const articlesToLoad = 20;

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numArticles: 0,
      category: this.props.match.params.category,
    }
  }

  async componentDidMount() {
    this.setState({
      numArticles: await Store.receive(GET_NUM_SECTION_ARTICLES, {section: this.state.category})
    })
  }

  getPage(offset) {
    return Store.receive(GET_SECTION_ARTICLES, { section:this.state.category, offset: offset });
  }

  render() {
    return (
      <div id={"section-page"} className={"page-content"}>
        <h1><b>{this.state.category.toLowerCase()}</b></h1>
        <PaginatedArticleList numArticles={this.state.numArticles}
                              getPage={(offset) => this.getPage(offset)} />
      </div>
    );
  }
}

export default withRouter(Section);
