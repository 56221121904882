// link for CKEditor: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html
// - ash

import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CKEditorImageModal from "./ckeditor/CKEditorImageModal";

class Editor extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }

    this.insertImage = this.insertImage.bind(this);
  }

  insertImage(imageLink) {
    // console.log(this.state.editor);
    this.state.editor.execute('imageInsert', {source: imageLink});
  }

  render() {
    try {
      return (
        <div className={"editor"}>
          <CKEditorImageModal insertImage={this.insertImage}/>
          <div className={"ckeditor"}>
            <CKEditor
              editor={ ClassicEditor }
              data={this.props.data}
              onInit={ editor => {
                // You can store the "editor" and use when it is needed.
                console.log( 'Editor is ready to use!', editor );
                this.setState({editor: editor});
              } }
              onChange={this.props.onChange}
              config={{
                toolbar: [
                  'heading',
                  'fontFamily',
                  'fontBackgroundColor',
                  'fontSize',
                  'fontColor',
                  '|',
                  'bold',
                  'italic',
                  'underline',
                  'strikethrough',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'todoList',
                  'code',
                  'specialCharacters',
                  '|',
                  'alignment',
                  'indent',
                  'outdent',
                  '|',
                  'mediaEmbed',
                  'blockQuote',
                  '|',
                  'pageBreak',
                  'horizontalLine',
                  '|',
                  'undo',
                  'redo',
                ],
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'poem',
                      view: { name: 'p', classes: 'indy-poem' },
                      title: 'Poem',
                      converterPriority: 'high' },
                    { model: 'close-lines',
                      view: { name: 'p', classes: 'indy-lines' },
                      title: 'Close Lines',
                      converterPriority: 'high' },
                    { model: 'extra-padded',
                      view: { name: 'p', classes: 'indy-padded' },
                      title: 'Extra Padded',
                      converterPriority: 'high' },
                    { model: 'masthead-heading',
                      view: { name: 'h4', classes: 'masthead-heading' },
                      title: 'Masthead Heading',
                      converterPriority: 'high' },
                  ]
                },
              }}
            />
          </div>
        </div>
      );
    } catch (err) {
      console.log(err);
    }
  }
}

export default Editor;
