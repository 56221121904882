import React, { Component} from 'react';
import { Switch, Route } from "react-router-dom";
import pages from './Pages/GuestPages/pages'
import { Header } from './Pages/Components/components';

class App extends Component {
  render() {
    return (
      <div>
        <Header/>
        <Switch>
          <Route path="/search"><pages.Search/></Route>
          <Route path="/about"><pages.About/></Route>
          <Route path="/apply"><pages.Apply/></Route>
          <Route path="/subscribe"><pages.Subscribe/></Route>
          <Route path="/alumni"><pages.Alumni/></Route>
          <Route path="/donate"><pages.Donate/></Route>
          <Route path="/article/:id"><pages.Article/></Route>
          <Route path="/list/:id"><pages.List/></Route>
          <Route path="/list"><pages.List/></Route>
          <Route path="/section=:category"><pages.Section/></Route>
          <Route path="/issues"><pages.Issues/></Route>
          <Route path="/issue/:issueId"><pages.PrevIssue/></Route>
          <Route path="/issuu/:id"><pages.IssuuReader/></Route>
          <Route path="/author=:author"><pages.Author/></Route>
          <Route path="/illustrator=:illustrator"><pages.Illustrator/></Route>
          <Route path="/:id"><pages.Article/></Route>
          <Route path="/"><pages.Home/></Route>
        </Switch>
      </div>
    );
  }
}



export default App;
