const SECTIONS = {
  ARTS: {
    name: "Arts",
    adminName: "Arts"
  },
  BULLETIN: {
    name: "Bulletin",
    adminName:"The Bulletin Board"
  },
  DEAR_INDY: {
    name: "Dear Indy",
    adminName: "Dear Indy"
  },
  EPHEMERA: {
    name: "Ephemera",
    adminName: "Ephemera"
  },
  FEATURES: {
    name: "Features",
    adminName: "Features"
  },
  FROM_THE_EDITORS: {
    name: "From the Editors",
    adminName: "FTE"
  },
  LIST: {
    name: "List",
    adminName: "List"
  },
  LITERARY: {
    name: "Literary",
    adminName: "Literary"
  },
  METABOLICS: {
    name: "Metabolics (Body)",
    adminName: "Metabolics"
  },
  METRO: {
    name: "Metro",
    adminName: "Metro"
  },
  NEWS: {
    name: "News",
    adminName: "News",
  },
  SCHEMA: {
    name: "Schema",
    adminName: "Schema"
  },
  SCIENCE_AND_TECH: {
    name: "Science & Tech",
    adminName: "Science & Technology"
  },
  SPANISH: {
    name: "Spanish",
    adminName: "Spanish"
  },
  VISCERA: {
    name: "Viscera",
    adminName: "Viscera"
  },
  WEEK_IN_REVIEW: {
    name: "Week in Review",
    adminName: "Week in Review"
  },
  WORLD: {
    name: "World",
    adminName: "World"
  },
  X: {
    name: "X",
    adminName: "X"
  },
  CLIMATE: {
    name: "Climate",
    adminName: "Climate"
  },
  OCCULT: {
    name: "Occult",
    adminName: "Occult"
  },
  INTERVIEWS: {
    name: "Interviews",
    adminName: "Interviews"
  }
  /*
    If you want to add a section to the Indy, first add it here as a new entry to SECTIONS. 
      name: display name is on the menu on the homepage
      adminName: usually the longer, more formal version of the name, displayed at the top of its section page 
  
   ***NOTE: adminName cannot be changed once it's added and used
   */
};

/*
  These are the sections that show up as individual items in the top menu.
  You can move the sections around, move it to ARCHIVE_SECTIONS, take it out, etc
 */
const MENU_SECTIONS = [
  SECTIONS.ARTS,
  SECTIONS.BULLETIN,
  SECTIONS.DEAR_INDY,
  SECTIONS.EPHEMERA,
  SECTIONS.FEATURES,
  SECTIONS.LITERARY,
  SECTIONS.METRO,
  SECTIONS.SCHEMA,
  SECTIONS.SCIENCE_AND_TECH,
  SECTIONS.VISCERA,
  SECTIONS.WEEK_IN_REVIEW,
  SECTIONS.WORLD,
  SECTIONS.X,
]

/*
  These are the sections that show up in the "archive" submenu 
 */
const ARCHIVE_SECTIONS = [
  SECTIONS.CLIMATE,
  SECTIONS.INTERVIEWS,
  SECTIONS.METABOLICS,
  SECTIONS.NEWS,
  SECTIONS.OCCULT,
  SECTIONS.SPANISH,
]

const ADMIN_SECTIONS = Object.values(SECTIONS);

module.exports = {
  MENU_SECTIONS,
  ARCHIVE_SECTIONS,
  ADMIN_SECTIONS
}