import React, { Component } from 'react';
import {pages} from "../pages";
import Store from '../Store.js';
import {GET_PAGE} from "../Actions";
import logo from "../../public/images/indy-logo-small.jpg"

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageId: pages.about.id,
      pageBody: "",
      loading: true
    }
  }

  async componentDidMount() {
    const page = await Store.receive(GET_PAGE, { pageId: this.state.pageId })
    this.setState({
      pageBody: page.body,
      loading: false,
    });
    this.resizeImages();
    this.analyzeMasthead();
  }

  resizeImages() {
    let images = document.getElementsByTagName("img");
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      image.style = "";
    }
  }
  
  analyzeMasthead() {
    // replace the <masthead> tag w/ the masthead div
    const beginningTag = "<p>&lt;masthead&gt;</p>";
    const endingTag = "<p>&lt;/masthead&gt;</p>";
    const mastheadBeginningTag = "<div class='masthead'>";
    const mastheadEndingTag = "</div>";
    let newPage = this.state.pageBody.replaceAll(beginningTag, mastheadBeginningTag)
    newPage = newPage.replaceAll(endingTag, mastheadEndingTag)

    // replace the <block> tag w/ a block div
    const blockBeginningTag = "<p>&lt;block&gt;</p>";
    const blockEndingTag = "<p>&lt;/block&gt;</p>";
    const mastheadBlockBeginningTag = "<div class='masthead-block'>";
    const mastheadBlockEndingTag = "</div>";
    newPage = newPage.replaceAll(blockBeginningTag, mastheadBlockBeginningTag)
    newPage = newPage.replaceAll(blockEndingTag, mastheadBlockEndingTag)
    
    this.setState({
      pageBody: newPage
    })
  }
  
  render() {
    return (
      <div id={"about-page"} className={"page-content"}>
        <div dangerouslySetInnerHTML={{__html: this.state.pageBody}}/>
      </div>
    );
  }
}



export default About;
