/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import adminPages from "./Pages/AdminPages/adminPages";
import "./public/stylesheets/admin.less";
import { Form, Input, Button, Alert } from "antd";

// import firebase from "firebase/compat/app";
// import "firebase/compat/auth";
// import { initializeApp } from "firebase/app";

// import Login from "./Pages/Login"
// import { useAuth } from "./AuthContext";

import { Layout, Menu } from "antd";

import { auth } from "./firebase";
// const { login } = useAuth();
const { Content } = Layout;

export default function Admin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [error, setError] = useState("");
  // const app = initializeApp(firebaseConfig);

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, pass);
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, [auth]);

  useEffect(() => {
    if (currentUser?.email == "theindy@gmail.com") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [currentUser]);

  async function handleSubmit(e) {
    console.log("logging in");
    try {
      await login(email, pass);
      console.log(currentUser);
    } catch {
      console.log(e);
      setError("failure");
      // console.log("failure");
    }
  }

  // setToken(userToken) {
  //   console.log(userToken);
  //   sessionStorage.setItem('token', JSON.stringify(userToken));
  // }

  // getToken() {
  //   const tokenString = sessionStorage.getItem('token');
  //   console.log(tokenString)
  //   const userToken = JSON.parse(tokenString);
  //   return tokenString
  // }

  // state = {
  //   collapsed: false,
  // };

  // onCollapse = collapsed => {
  //   console.log(collapsed);
  //   this.setState({ collapsed });
  // };

  // [token, setToken] = useState();

  // let token = this.getToken();
  // if(!token) {
  //   console.log(token);
  //   console.log("yes");
  //   // return <div>LOL</div>
  //   return <Login setToken={this.setToken} />
  // }
  // else {
  //   console.log('test');
  if (isLoggedIn) {
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          <Menu mode="horizontal">
            <Menu.Item key="theindy">
              <a href="/">the indy</a>
            </Menu.Item>
            <Menu.Item key="admin">
              <a href="/admin">admin</a>
            </Menu.Item>
            <Menu.Item key="0">
              <a href="/admin/pages">pages</a>
            </Menu.Item>
            <Menu.Item key="1">
              <a href="/admin/articles">articles</a>
            </Menu.Item>
            <Menu.Item key="2">
              <a href="/admin/issues">issues</a>
            </Menu.Item>
            <Menu.Item key="3">
              <a href="/admin/lists">the list</a>
            </Menu.Item>
            <Menu.Item key="4">
              <a href="/admin/alums">alums</a>
            </Menu.Item>
            <Menu.Item key="5">
              <a href="/admin/emails">emails</a>
            </Menu.Item>
            <Menu.Item key="6">
              <a href="/admin/archive">archive</a>
            </Menu.Item>
            <Menu.Item>
              <Button onClick={() => auth.signOut()}>Sign Out</Button>
            </Menu.Item>
          </Menu>
          <Layout style={{ padding: "2% 5%" }}>
            <Content>
              <Switch>
                <Route exact path={`/admin/pages`} key="pages">
                  <adminPages.Pages />
                </Route>
                <Route exact path={`/admin/emails`} key="emails">
                  <adminPages.Emails />
                </Route>
                <Route exact path={`/admin/lists`} key="lists">
                  <adminPages.Lists />
                </Route>
                <Route exact path={`/admin/issue/:id/false`}>
                  <adminPages.Issue official={false} />
                </Route>
                <Route exact path={`/admin/issues`}>
                  <adminPages.Issues official={false} />
                </Route>
                <Route exact path={`/admin/article/:id`} key="edit-article">
                  <adminPages.Article />
                </Route>
                <Route exact path={`/admin/articles`}>
                  <adminPages.Articles />
                </Route>
                <Route exact path={`/admin/alums`}>
                  <adminPages.Alumni />
                </Route>
                <Route path={`/admin`}>
                  <adminPages.Home />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  } else {
    return (
      <div className="login-admin">
        <h2>ur logging into the indy :)</h2>
        <h3>wassup</h3>
        <Input
          className="login-admin-field"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Email"
        />
        <br />
        <Input
          className="login-admin-field"
          onChange={(e) => setPass(e.target.value)}
          value={pass}
          type="password"
          placeholder="Password"
        />
        <br />
        <Button onClick={handleSubmit} className="login-admin-button">
          Login
        </Button>
        {/* <Alert>{error}</Alert>; */}
        {/* <Button
          onClick={() => auth.createUserWithEmailAndPassword(email, pass)}
        >
          Signup Test
        </Button> */}
      </div>
    );
  }
}
