import Actions from './Actions.js';
import AdminActions from './AdminActions.js';
import queryString from "query-string";

 export default {
  receive,
  adminReceive,
}

async function receive(action, req={}, callback=null) {
  try {
    if (process.env.NODE_ENV === "development") {
      console.log("Action received!")
      console.log("\tACTION: %s", action);
      console.log("\treq: %o", req);
    }
    
    let response;
    switch(action) {
      case Actions.GET_ARTICLE:
        response = await GET(`/api/article/get-article/${req.articleId}`, req);
        break;
      case Actions.GET_SECTION_ARTICLES:
        response = await GET(`/api/article/section=${req.section}/${req.offset}`);
        break;
      case Actions.GET_NUM_SECTION_ARTICLES:
        response = await GET(`/api/article/section=${req.section}/length`);
        break;
      case Actions.GET_AUTHOR_ARTICLES:
        response = await GET(`/api/article/author=${req.author}/${req.offset}`);
        break;
      case Actions.GET_NUM_AUTHOR_ARTICLES:
        response = await GET(`/api/article/author=${req.author}/length`);
        break;
      case Actions.GET_ILLUSTRATOR_ARTICLES:
        response = await GET(`/api/article/illustrator=${req.illustrator}/${req.offset}`);
        break;
      case Actions.GET_NUM_ILLUSTRATOR_ARTICLES:
        response = await GET(`/api/article/illustrator=${req.illustrator}/length`);
        break;
      case Actions.GET_ARTICLES_FROM_ISSUE:
        response = await GET(`/api/article/get-issue-articles/${req.issueId}`);
        break;
      case Actions.SEARCH_ARTICLES:
        response = await GET_v2('/api/article/search-articles/0', req);
        break;
        
      case Actions.GET_ISSUE_FROM_ID:
        response = await GET(`/api/issue/get-issue/${req.issueId}`);
        break;
      case Actions.GET_MOST_RECENT_ISSUE:
        response = await GET(`/api/issue/get-most-recent-issue`);
        break;
      case Actions.GET_PUBLISHED_ISSUES:
        response = await GET(`/api/issue/get-issues/${req.limit}/${req.offset}`);
        break;
      case Actions.GET_NUM_PUBLISHED_ISSUES:
        response = await GET(`/api/issue/get-issues/length`);
        break;

      case Actions.ADD_EMAIL:
        response = await POST('/api/email/add-email', req);
        break;

      case Actions.GET_LIST:
        response = await GET(`/api/list/get-list/${req.listId}`);
        break;
      case Actions.GET_NUM_LISTS:
        response = await GET(`/api/list/get-lists/length`);
        break;
      case Actions.GET_LISTS:
        response = await GET(`/api/list/get-lists/${req.offset}`);
        break;
      case Actions.GET_MOST_RECENT_LIST:
        response = await GET(`/api/list/get-most-recent-list`);
        break; 
        
      case Actions.ADD_ALUM:
        response = await POST(`/api/alum/add-alum`, req);
        break;

      case Actions.GET_CKEDITOR_CONTENTS:
        response = await POST(`/aws/ckeditor`, req);
        break;
      case Actions.ADD_CKEDITOR_FOLDER:
        response = await POST(`/aws/add-ckeditor-folder`, req);
        break;
      case Actions.DELETE_CKEDITOR_OBJECT:
        response = await DELETE('/aws/delete-ckeditor-object', req);
        break;

      case Actions.GET_PAGE:
        response = await GET_v2('/api/page/get-page', req);
        break;
      default:
        console.error("This action does not exist.");
        throw new Error();
      // break;
    }

    if (process.env.NODE_ENV === "development") {
      if (response.ok) {
        console.log("Action successful");
      } else {
        console.error("the action was not successful. pls investigate in ../Store.js");
      }
    }
    
    if (response.ok) {
      return response.json();
    } else {
      return response.json();
      // throw response;
    }
    
  } catch(err) {
    console.error("There was an issue when attempting to complete this action.")
  }
}

async function adminReceive(action, req={}, callback=null) {
  try {
    console.log("Action received!")
    console.log("\tACTION: %s", action);
    console.log("\treq: %o", req);

    let response;
    switch(action) {
      case AdminActions.ADD_ARTICLE:
        response = await POST(`/api/article/admin/add-article`, req);
        break;
      case AdminActions.UPDATE_ARTICLE:
        response = await PUT(`/api/article/admin/update-article`, req);
        break;
      case AdminActions.DELETE_ARTICLE:
        response = await DELETE(`/api/article/admin/delete-article`, req);
        break;
      case AdminActions.GET_ARTICLE:
        response = await GET(`/api/article/admin/get-article/${req.articleId}`, req);
        break;
      case AdminActions.GET_ALL_ARTICLES:
        response = await GET(`/api/article/admin/get-articles/${req.offset}`, req);
        break;
      case AdminActions.GET_NUM_ALL_ARTICLES:
        response = await GET('/api/article/admin/get-articles/length', req);
        break;
      case AdminActions.GET_ARTICLES_FROM_ISSUE:
        response = await GET(`/api/article/admin/get-articles-for-issue/${req.issueId}`);
        break;

      case AdminActions.ADD_ISSUE:
        response = await POST(`/api/issue/admin/add-issue/${req.official}`, req);
        break;
      case AdminActions.UPDATE_ISSUE:
        response = await PUT(`/api/issue/admin/update-issue/${req.official}`, req);
        break;
      case AdminActions.DELETE_ISSUE:
        response = await DELETE(`/api/issue/admin/delete-issue/${req.official}`, req);
        break;
      case AdminActions.GET_ISSUE_FROM_ID:
        response = await GET(`/api/issue/admin/get-issue/${req.issueId}/${req.official}`);
        break;
      case AdminActions.GET_NUM_ALL_ISSUES:
        response = await GET(`/api/issue/admin/get-issues/length`, req);
        break;
      case AdminActions.GET_ALL_ISSUES:
        response = await GET(`/api/issue/admin/get-issues/${req.offset}/${req.official}`);
        break;
      case AdminActions.GET_ISSUES_TO_SELECT:
        response = await GET(`/api/issue/admin/get-selectable-issues`);
        break;

      case AdminActions.GET_EMAILS:
        response = await GET('/api/email/get-emails');
        break;

      case Actions.UPLOAD_LIST:
        response = await POST_v2(`/upload-avatar`, req);
        break;
      case Actions.GET_LISTS:
        response = await GET('/db/thelists/get-lists');
        break;
      case Actions.GET_MOST_RECENT_LIST:
        response = await GET('/db/thelists/get-most-recent-list');
        break;
      case AdminActions.DELETE_LIST:
        response = await DELETE('/api/list/admin/delete-list', req);
        break;

      case AdminActions.GET_ALUMS:
        response = await GET(`/api/alum/get-alums`, req);
        break;

      case AdminActions.GET_CKEDITOR_CONTENTS:
        response = await POST(`/aws/ckeditor`, req);
        break;
      case AdminActions.ADD_CKEDITOR_FOLDER:
        response = await POST(`/aws/add-ckeditor-folder`, req);
        break;
      case AdminActions.DELETE_CKEDITOR_OBJECT:
        response = await DELETE('/aws/delete-ckeditor-object', req);
        break;

      case AdminActions.UPDATE_PAGE:
        response = await PUT('/api/page/admin/update-page', req);
        break;
        
      default:
        console.error("This action does not exist.");
        throw new Error();
    }

    if (response.ok) {
      console.log("Action successful");
      return response.json();
    } else {
      console.error("the action was not successful. pls investigate in ../Store.js");
      return response;
    }
  } catch(err) {
    console.error("There was an issue when attempting to complete this action.")
  }
}

async function GET(endpoint, req=null){
  let response = await fetch(endpoint);
  if (process.env.NODE_ENV === "development") {
    console.log("Response: %o", response)
  }
  return response;
}

async function GET_v2(endpoint, req){
  const queryParams = queryString.stringify(req);
  const url = `${endpoint}?${queryParams}`;
  let response = await fetch(url);
  if (process.env.NODE_ENV === "development") {
    console.log("Response: %o", response)
  }
  return response;
}

async function POST(endpoint, req){
  let response = await fetch(endpoint, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(req),
  });
  return response;
}

async function POST_v2(endpoint, req){
  let response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    body: req,
  });
  return response;
}

async function PUT(endpoint, req){
  let response = await fetch(endpoint, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(req),
  });
  return response;
}

async function DELETE(endpoint, req=null){
  let response = await fetch(endpoint, {
    method: "DELETE",
    body: JSON.stringify(req),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return response;
}
