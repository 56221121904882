import About from "./About";
import Alumni from "./Alumni";
import Apply from "./Apply";
import Article from "./Article";
import Author from "./Author";
import Donate from "./Donate";
import Home from "./Home";
import Illustrator from "./Illustrator";
import PrevIssue from "./PrevIssue";
import Issues from "./Issues";
import IssuuReader from "./IssuuReader";
import Section from "./Section";
import Subscribe from "./Subscribe";
import List from "./List";
import Search from "./Search";

export default {
  About,
  Alumni,
  Apply,
  Article,
  Author,
  Donate,
  Home,
  Illustrator,
  PrevIssue,
  Issues,
  IssuuReader,
  List,
  Search,
  Section,
  Subscribe,
};
