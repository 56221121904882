import React, { Component } from "react";
import { IssueCover } from "../Components/components";
import { Table, Checkbox, Button } from "antd";
import Store from "../Store";
import { ADD_ISSUE, GET_ALL_ISSUES, GET_NUM_ALL_ISSUES } from "../AdminActions";
import { imageSizes } from "../Components/IssueCover";

const ISSUES_TO_LOAD = 50;

class Issues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      official: props.official,
      loading: true,

      numIssues: 0,
      issues: [],

      pageCache: new Map(),
    };

    this.addIssue = this.addIssue.bind(this);
    this.fetchPage = this.fetchPage.bind(this);
  }

  async componentDidMount() {
    this.setState({
      numIssues: await Store.adminReceive(GET_NUM_ALL_ISSUES),
    });
    this.fetchPage(1);
  }

  async addIssue() {
    Store.adminReceive(ADD_ISSUE, { official: this.state.official }).then(
      (res) =>
        (window.location.href = `/admin/issue/${res.id}/${this.state.official}`)
    );
  }

  async fetchPage(page) {
    this.setState({ loading: true });
    if (this.state.pageCache.has(page)) {
      this.setState({ issues: this.state.pageCache.get(page), loading: false });
    } else {
      const offset = (page - 1) * ISSUES_TO_LOAD;
      const issues = await Store.adminReceive(GET_ALL_ISSUES, {
        offset: offset,
        official: this.state.official,
      });
      this.state.pageCache[page] = issues;
      this.setState({
        issues: issues,
        loading: false,
      });
    }
  }

  render() {
    const paginationConfig = {
      showQuickJumper: true,
      defaultPageSize: ISSUES_TO_LOAD,
      showSizeChanger: false,
      defaultCurrent: 1,
      total: this.state.numIssues,
      onChange: this.fetchPage,
    };
    const columns = [
      {
        title: "Edit",
        dataIndex: "id",
        key: "editButton",
        render: (id) => (
          <Button href={`/admin/issue/${id}/${this.props.official}`}>
            edit
          </Button>
        ),
      },
      {
        title: "Cover",
        dataIndex: "document_number",
        key: "cover",
        alt: "Issue",
        render: (document_number) => (
          <IssueCover
            document_number={document_number}
            size={imageSizes.SMALL}
          />
        ),
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (title, issue) => (
          <a href={`/admin/issue/${issue.id}/${this.state.official}`}>
            {title}
          </a>
        ),
      },
      {
        title: "Type",
        dataIndex: "scribd",
        key: "type",
        render: (scribd) => (scribd ? "scribd" : "issuu"),
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Published",
        dataIndex: "published",
        key: "published",
        render: (published) => <Checkbox checked={published} />,
      },
    ];

    return (
      <div>
        <h2>Issues</h2>
        <div className={"add"}>
          <Button
            className={"admin-button"}
            type="primary"
            onClick={this.addIssue}
          >
            add issue
          </Button>
        </div>
        <Table
          loading={this.state.loading}
          columns={columns}
          dataSource={this.state.issues}
          size={"small"}
          pagination={paginationConfig}
        />
      </div>
    );
  }
}

export default Issues;
